import React from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row, Button } from 'reactstrap';
import ConfigLink from '../../../core/configs/config.link';

import AccountLayout from './Components/AccountLayout';

function WrongFlow({ history }) {
  return (
    <AccountLayout>
      <Row>
        <Col className="text-left mx-auto px-md-4 vl-reset-your-pass-page" sm="12" md={{ size: 5 }}>
          <p className="mt-5 account-page-label">Temporary password required</p>

          <p className="mt-3 account-text">
            We have just emailed you a copy of your temporary password. To log in for the first time, please log in with
            your email address and temporary password. You will then be prompted to enter a permanent password.
          </p>

          <Button onClick={() => history.push(ConfigLink.AccountLogin)}>Return to login</Button>
        </Col>
      </Row>
    </AccountLayout>
  );
}

export default withRouter(WrongFlow);
