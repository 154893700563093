import React, { useState, ReactNode, useRef } from 'react';
import { Button, ButtonProps } from 'ALIASED-antd';

type ClickHandler =
  | ((e: React.MouseEvent<HTMLButtonElement>) => void)
  | ((e: React.MouseEvent<HTMLButtonElement>) => Promise<void>);

interface AsyncAntdButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClick?: ClickHandler;
  children?: ReactNode;
  initiallyDisabled?: boolean;
}

const AsyncAntdButton: React.FC<AsyncAntdButtonProps> = ({ onClick, children, initiallyDisabled = false, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(initiallyDisabled);
  const timeoutRef = useRef<number | null>(null);

  const handleAsyncClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      if (onClick) {
        setButtonDisabled(true);

        timeoutRef.current = window.setTimeout(() => {
          setLoading(true);
        }, 300);

        await onClick(e);
      }
    } catch (error) {
      console.error('Error during async operation:', error);
    } finally {
      setLoading(false);
      setButtonDisabled(false);

      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    }
  };

  return (
    <Button onClick={handleAsyncClick} loading={loading} disabled={buttonDisabled || rest.disabled} {...rest}>
      {children}
    </Button>
  );
};

export default AsyncAntdButton;
