import ServiceApi from '../services/service.api';
import format from '../lib/formatDate';

const baseServiceUrl = `/tasks`;

const ApiTask = {
  getTaskList: (params = {}) => {
    const url = `${baseServiceUrl}/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  cancel: (params) => {
    params.user_guid = ServiceApi.userGuid;
    console.debug('CANCEl TASK PRE-BODY', params);
    return ServiceApi.post(`${baseServiceUrl}/cancel`, params);
  },
  complete: (params) => {
    params.user_guid = ServiceApi.userGuid;
    return ServiceApi.post(`${baseServiceUrl}/complete`, params);
  },
  reassignBulk: (params) => {
    params.user_guid = ServiceApi.userGuid;
    return ServiceApi.post(`${baseServiceUrl}/reassign/bulk`, params);
  },
  reassign: (params) => {
    params.user_guid = ServiceApi.userGuid;
    return ServiceApi.post(`${baseServiceUrl}/reassign`, params);
  },
  createATask: (
    task_type = '',
    task_sub_type = '',
    patientLookupGuid = '',
    assignToGuid = '',
    caseSelectionId = '',
    dateObject,
    timeObject,
    notes = ''
  ) => {
    const url = `${baseServiceUrl}/create`;

    let d = format(dateObject, 'yyyy-MM-dd');
    let t = null; //time is not mandatory!
    if (timeObject) {
      t = format(timeObject, 'HH:mm:ss');
    } else {
      t = '00:00:00';
    }
    var dateTime = `${d}T${t}`; //YYYY-MM-DDThh:mm:ss

    const body = {
      user_guid: ServiceApi.userGuid,
      patient_guid: patientLookupGuid,
      case_id: `${caseSelectionId}`,
      task_type_code: task_type,
      appt_type_code: task_sub_type,
      description: notes,
      affected_user_guid: '',
      assignee_user_guid: assignToGuid,
      due_date: dateTime
    };
    console.debug('CREATE TASK PRE-BODY:', body);
    return ServiceApi.put(url, body);
  },
  getTaskAssignee: (params) => {
    const url = `${baseServiceUrl}/assignee/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  getDashboardCurrent: (params = {}) => {
    const url = `${baseServiceUrl}/dashboard/current/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  getDashboardStats: (params = {}) => {
    const url = `${baseServiceUrl}/dashboard/stats/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  getDashboardTasks: (params = {}) => {
    const url = `${baseServiceUrl}/dashboard/tasks/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  putEligibility: (client_case_Id, mem_no, eligibilityStatusCode, caseId, patient_guid) => {
    const url = `/referrers/eligibility/status`;
    const body = {
      client_case_id: client_case_Id,
      pat_guid: patient_guid,
      case_id: caseId,
      eligibility_check_status_code: eligibilityStatusCode,
      user_guid: ServiceApi.userGuid,
      membership_no: mem_no
    };
    return ServiceApi.put(url, body);
  },
  getDashboardAssignedTasks: () => {
    const url = `${baseServiceUrl}/dashboard/tasks/${ServiceApi.userGuid}`;
    const params = {
      rows: 2,
      assignee: ServiceApi.userGuid
    };
    return ServiceApi.get(url, { params });
  },
  getDashboardTasksRank: () => {
    const url = `${baseServiceUrl}/dashboard/rank/${ServiceApi.userGuid}`;
    const params = {
      period: 'month',
      rows: 3
    };
    return ServiceApi.get(url, { params });
  },
  postReopenTask: (task_id) => {
    const url = `${baseServiceUrl}/reopen/${ServiceApi.userGuid}`;
    const body = {
      task_id
    };
    return ServiceApi.post(url, body);
  }
};

export default ApiTask;
