import React from 'react';
import { Button, Spinner } from 'reactstrap';
import classnames from 'classnames';

const LoadingButton = ({ children, loading = false, block = false, textStyle = {}, ...rest }) => (
  <Button {...rest} block={block}>
    {!(block && !loading) && (
      <Spinner
        className={classnames({
          'position-relative': true,
          'button-style': !block,
          visible: loading,
          invisible: !loading
        })}
        size="sm"
        style={styles.spinner(loading)}
      />
    )}
    {!(block && loading) && (
      <span
        style={{ ...styles.loadingSpan(loading), ...textStyle }}
        className={classnames({
          visible: !loading,
          'span-style': !block
        })}
      >
        {children}
      </span>
    )}
  </Button>
);

export { LoadingButton };

const styles = {
  spinner: (loading) => ({
    display: loading ? 'inline-block' : 'none',
    marginBottom: '2px'
  }),
  loadingSpan: (loading) => ({ marginLeft: loading ? '5px' : '0px' })
};
