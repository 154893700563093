// @ts-check
import user from './api.user';
import task from './api.task';
import clinicians from './api.clinicians';
import admin from './api.admin';
import providerToken from './api.provider-token';
import providers from './api.providers';
import proxies from './api.proxies';
import referrers from './api.referrers';
import referrer from './api.referrer';
import cases from './api.cases';
import booking from './api.booking';
import file from './api.file';
import meta from './api.meta';
import gdpr from './api.gdpr';
import tags from './api.tags';
import notes from './api.notes';
import outputs from './api.outputs';
import configTool from './api.config-tool';
import audit from './api.audit';

const Api = {
  user,
  clinicians,
  admin,
  providerToken,
  providers,
  proxies,
  task,
  referrers,
  referrer,
  cases,
  booking,
  file,
  meta,
  gdpr,
  tags,
  notes,
  outputs,
  configTool,
  audit
};

export { Api };
