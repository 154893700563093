import ServiceApi from '../services/service.api';

const baseServiceUrl = `/users`;

const ApiUser = {
  getFAQList: (params = {}) => {
    const url = `${baseServiceUrl}/faq/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  sendTwilioEvent: (user_guid, appointment_id, event, message = '') => {
    const url = `/users/appt/twilio/event`;
    return ServiceApi.put(url, {
      user_guid,
      appt_id: appointment_id,
      success: true,
      event,
      message
    });
  },
  getTwilioEvents: (params = {}) => {
    const url = `${baseServiceUrl}/appt/event/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  timeline: (params = {}) => {
    const url = `${baseServiceUrl}/timeline/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  getCurrentUser: () => {
    return ServiceApi.getCurrentUser();
  },
  refreshToken: (token) => {
    return ServiceApi.refreshToken(token);
  },
  checkPassword: (email, body) => {
    const url = `/super/user/password/${email}`;
    return ServiceApi.put(url, body);
  },
  updateProfile: (body) => {
    const url = `${baseServiceUrl}/profile`;
    return ServiceApi.post(url, body);
  },
  resetPassword: (body) => {
    const url = `/reset-password`;
    return ServiceApi.post(url, body, false);
  },
  changePassword: (body) => {
    const url = `/change-password`;
    return ServiceApi.post(url, body);
  },
  logoutUser: () => {
    const url = `${baseServiceUrl}/logout/${ServiceApi.userGuid}`;
    const body = {};
    return ServiceApi.put(url, body);
  },
  newPassword: (body) => {
    const url = `/new-password`;
    return ServiceApi.post(url, body, false);
  },
  forgotPassword: (body) => {
    const url = `/forgot-password`;
    return ServiceApi.post(url, body, false);
  }
};

export default ApiUser;
