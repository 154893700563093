import ServiceApi from '../services/service.api';

const baseServiceUrl = `/admin`;

const ApiAdmin = {
  powerbi: {
    getReport: (params) => {
      const url = `/powerbi`;
      return ServiceApi.get(url, { params });
    },
    getGroups: () => {
      const url = `${baseServiceUrl}/powerbi/groups/${ServiceApi.userGuid}`;
      return ServiceApi.get(url);
    }
  },
  company: {
    getAll: (params) => {
      const url = `${baseServiceUrl}/companies/${ServiceApi.userGuid}`;
      return ServiceApi.get(url, { params });
    },
    addClient: (params) => {
      const url = `${baseServiceUrl}/company/add`;
      const body = {
        ...params,
        user_guid: ServiceApi.userGuid
      };
      return ServiceApi.put(url, body);
    },
    editClient: (params) => {
      const url = `${baseServiceUrl}/company`;
      const body = {
        ...params,
        user_guid: ServiceApi.userGuid
      };
      return ServiceApi.post(url, body);
    }
  },
  employer: {
    getAll: (params) => {
      const url = `${baseServiceUrl}/employers/${ServiceApi.userGuid}`;
      return ServiceApi.get(url, { params });
    }
  },
  Users: {
    GetAll: (params) => {
      const url = `${baseServiceUrl}/admin/users/${ServiceApi.userGuid}`;
      return ServiceApi.get(url, { params });
    }
  },
  AdminUsers: {
    GetDocuments: (apptId, caseId, pageNumber) => {
      const url = `/clinicians/appt/letter_files/${ServiceApi.userGuid}?appt_id=${
        apptId ? apptId : ''
      }&page=${pageNumber}&case_id=${caseId}`;
      return ServiceApi.get(url);
    },
    GetDocumentsByCase: (params) => {
      const url = `/clinicians/appt/letter_files/${ServiceApi.userGuid}?case_id=${params}`;
      return ServiceApi.get(url, { params });
    },
    GetFile: (id, file, temp = false) => {
      const url = `/file?user_guid=${id}&filename=${file}&tempurl=${temp ? 'true' : ''}`;
      return ServiceApi.get(url);
    },
    postReason: (appt_id, case_id, reason) => {
      const url = `/gdpr/request`;
      const body = {
        appt_id: appt_id ? appt_id : '',
        case_id: case_id,
        user_guid: ServiceApi.userGuid,
        process_code: 'ACCESS_TIMELINE',
        reason_code: reason
      };
      return ServiceApi.put(url, body);
    },
    GetAuditLogs: (apptId, caseId, pageNumber) => {
      const url = `/gdpr/${ServiceApi.userGuid}?order&appt_id=${
        apptId ? apptId : ''
      }&case_id=${caseId}&page=${pageNumber}&process_code=ACCESS_TIMELINE`;
      return ServiceApi.get(url);
    }
  },
  ReferrerUsers: {
    all_referrer_users: (params) => {
      // params => page,rows,sort,order,email,name,enabled => order=(fname|lname|id|status|email)
      const url = `${baseServiceUrl}/referrer/users/${ServiceApi.userGuid}`;
      return ServiceApi.get(url, { params });
    }
  },
  getAllRoles: () => {
    const url = `${baseServiceUrl}/roles/${ServiceApi.userGuid}`;
    return ServiceApi.get(url);
  },
  upsert_a_faq: ({ faq_id = null, priority = 1, question = '', answer = '', enabled = true }) => {
    const url = `${baseServiceUrl}/faq`;
    const body = {
      user_guid: ServiceApi.userGuid,
      faq_id, // null ro create new one. or set number
      priority, // number
      question,
      answer,
      enabled //boolean
    };
    return ServiceApi.post(url, body);
  },
  UpsertUser: (data) => {
    const url = `${baseServiceUrl}/user`;
    return ServiceApi.post(url, {
      user_guid: ServiceApi.userGuid,
      ...data
    });
  },

  disable: (data) => {
    const url = `${baseServiceUrl}/user/disable`;
    return ServiceApi.post(url, {
      user_guid: ServiceApi.userGuid,
      ...data
    });
  },

  getSpecialistCategory: (params) => {
    const url = `${baseServiceUrl}/speccats/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  getSpecificAreas: (params) => {
    const url = `${baseServiceUrl}/specareas/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  UpsertClinician: (data) => {
    const url = `${baseServiceUrl}/clinician`;
    return ServiceApi.post(url, {
      user_guid: ServiceApi.userGuid,
      ...data
    });
  },
  getDashboard_HBS_Admin: (period, client_id) => {
    const url = `${baseServiceUrl}/hbs_dashboard/${ServiceApi.userGuid}`;
    const params = {
      period,
      client_id
    };
    console.debug('DASHBOARD-PARAM:', {});
    return ServiceApi.get(url, params);
  },
  getDashboardTaskStats: (params) => {
    const url = `${baseServiceUrl}/hbs_dashboard/tasks`;
    return ServiceApi.get(url, { params: { ...params, us_user_guid: ServiceApi.userGuid } });
  },
  changeUserEmail: (email = '', guid = undefined) => {
    const url = `/change-email`; // this is lambda function, it does not need baseServiceUrl
    const body = {
      up_user_guid: guid ? guid : ServiceApi.userGuid,
      up_user_email: email
    };
    console.debug('CHANGE-USER-EMAIL PRE-BODY:', body);
    return ServiceApi.post(url, body);
  },
  confirmEmailChange: (token = '') => {
    const url = `/confirm-change`; // this is lambda function, it does not need baseServiceUrl
    const body = {
      token
    };
    console.debug('CONFIRM-CHANGE PRE-BODY:', body);
    return ServiceApi.post(url, body, false);
  },
  Support: {
    getArticle: (article) => {
      const url = `/article/${article}`;
      return ServiceApi.get(url);
    }
  },
  Assessment: {
    getAll: (params) => {
      const url = `${baseServiceUrl}/surveyjs/def/${ServiceApi.userGuid}`;
      return ServiceApi.get(url, { params });
    },
    getByIdWithJson: (id) => {
      const url = `${baseServiceUrl}/surveyjs/def/${ServiceApi.userGuid}?surveyjs_def_id=${id}&draft=true`;
      return ServiceApi.get(url);
    },
    getByIdWithJsonLatest: (id) => {
      const url = `${baseServiceUrl}/surveyjs/def/${ServiceApi.userGuid}?surveyjs_def_id=${id}&draft=false`;
      return ServiceApi.get(url);
    },
    getEvents: () => {
      const url = `${baseServiceUrl}/surveyjs/events/${ServiceApi.userGuid}`;
      return ServiceApi.get(url);
    },
    createAssessment: (data) => {
      const url = `${baseServiceUrl}/surveyjs/new`;
      return ServiceApi.put(url, { ...data, user_guid: ServiceApi.userGuid });
    },
    saveAsDraft: (id, title, shortname, always_edit, data) => {
      const url = `${baseServiceUrl}/surveyjs`;
      return ServiceApi.post(url, {
        surveyjs_def_id: id,
        title: title,
        shortname: shortname,
        always_edit: always_edit === true ? 1 : 0,
        publish: false,
        survey_json: data,
        user_guid: ServiceApi.userGuid
      });
    },
    saveAsPublished: (id, title, shortname, always_edit, data) => {
      const url = `${baseServiceUrl}/surveyjs`;
      return ServiceApi.post(url, {
        surveyjs_def_id: id,
        title: title,
        shortname: shortname,
        always_edit: always_edit === true ? 1 : 0,
        publish: true,
        survey_json: data,
        user_guid: ServiceApi.userGuid
      });
    },
    disable: (id) => {
      const url = `${baseServiceUrl}/surveyjs/disable`;
      return ServiceApi.post(url, {
        survey_def_id: id,
        enabled: false,
        user_guid: ServiceApi.userGuid
      });
    },
    enable: (id) => {
      const url = `${baseServiceUrl}/surveyjs/disable`;
      return ServiceApi.post(url, {
        survey_def_id: id,
        enabled: true,
        user_guid: ServiceApi.userGuid
      });
    }
  },
  PIFU: {
    getPIFUs: (params) => {
      const url = `${baseServiceUrl}/patients/pifu`;
      return ServiceApi.get(url, { params: { ...params, p_user_guid: ServiceApi.userGuid } });
    }
  },
  Schedule: {
    getAll: (params) => {
      const url = `${baseServiceUrl}/survey/schedule/${ServiceApi.userGuid}`;
      return ServiceApi.get(url, { params });
    }
  },
  updateInvestigationStatus: (ireq_id, state) => {
    return ServiceApi.post(`${baseServiceUrl}/invreq/status`, {
      user_guid: ServiceApi.userGuid,
      id: ireq_id,
      ireq_state_code: state
    });
  },
  cancelInvestigationRequest: (ireq_id, reason) => {
    return ServiceApi.post(`${baseServiceUrl}/invreq/status`, {
      user_guid: ServiceApi.userGuid,
      id: ireq_id,
      ireq_state_code: 'CANCELLED',
      ireq_cancel_code: reason
    });
  },
  updateInvestigationApptDetails: (ireq_id, appt_date) => {
    return ServiceApi.post(`${baseServiceUrl}/invreq/appt_date/${ServiceApi.userGuid}/`, {
      id: ireq_id,
      appt_date: appt_date,
      token: ServiceApi.token
    });
  },
  getInvestigationAudit: (case_id, rows = 999) => {
    return ServiceApi.get(`${baseServiceUrl}/invreq/audit/${ServiceApi.userGuid}`, { params: { case_id, rows } });
  },
  Queues: {
    getQueues: () => ServiceApi.get(`${baseServiceUrl}/queues/${ServiceApi.userGuid}`),
    updateQueue: (params) => {
      return ServiceApi.post(`${baseServiceUrl}/queue`, {
        user_guid: ServiceApi.userGuid,
        cr_queue_code: params.cr_queue_code,
        cr_low_threshold: params.cr_low_threshold,
        cr_high_threshold: params.cr_high_threshold,
        urg_code: params.urg_code
      });
    }
  },
  OutcomesCodes: {
    getAll: (params) => {
      const url = `${baseServiceUrl}/appt/outcomes/${ServiceApi.userGuid}`;
      return ServiceApi.get(url, { params });
    }
  },
  client: {
    getOutcomes: ({
      page = 1,
      rows = 10,
      order = 'ASC',
      sort = undefined,
      outcome_code = undefined,
      client_id = undefined,
      spec_cat_code = undefined
    }) => {
      const url = `${baseServiceUrl}/client/outcomes/${ServiceApi.userGuid}`;
      const params = { page, rows, order, outcome_code, sort, client_id, spec_cat_code };

      return ServiceApi.get(url, { params });
    },
    postOutcome: (outcome) =>
      ServiceApi.post(`${baseServiceUrl}/client/outcome`, {
        user_guid: ServiceApi.userGuid,
        us_user_guid: ServiceApi.userGuid,
        ...outcome
      }),
    deleteOutcome: ({ client_id, appt_outcome_code, spec_cat_code }) => {
      const url = `${baseServiceUrl}/client/outcome/delete`;
      const params = {
        user_guid: ServiceApi.userGuid,
        p_user_guid: ServiceApi.userGuid,
        client_id,
        appt_outcome_code,
        spec_cat_code
      };

      return ServiceApi.put(url, params);
    },
    createOutcome: (outcomeCode, description) =>
      ServiceApi.post(`${baseServiceUrl}/appt/outcome`, {
        user_guid: ServiceApi.userGuid,
        appt_outcome_code: outcomeCode,
        description
      })
  }
};

export default ApiAdmin;
