// @ts-nocheck
import React, { useState, useContext } from 'react';
import ContextApp from './context.app';
import { AppointmentContextWrapper } from '../lib/context/Appointment';

function ProviderApp({ children }) {
  const [systemType, setSystemType] = useState('admin');
  const [auth, setAuth] = useState({
    user: {},
    isAuthenticated: false,
    authentication: false,
    reload: false,
    logRocketSessionURL: ''
  });
  const [referrerAppointment, setReferrerAppointment] = useState({
    clinguId: null,
    date: null,
    caseId: null,
    type: null,
    clinicianSubType: null,
    appId: null
  });
  const [selectedTask, setSelectedTask] = useState({});

  const ctxApp = {
    auth,
    setAuth,
    referrerAppointment,
    setReferrerAppointment,
    systemType,
    setSystemType,
    selectedTask,
    setSelectedTask
  };

  return (
    <AppointmentContextWrapper>
      <ContextApp.Provider value={ctxApp}>{children}</ContextApp.Provider>
    </AppointmentContextWrapper>
  );
}

export default ProviderApp;

//=======================================Provide here all custom hooks related to Context
/**
 * @example
   import { useSystemType,enumSystemTypes } from "../../../core/providers/provider.app";
   const [systemType, setSystemType] = useSystemType();
 */
const useSystemType = () => {
  const { systemType, setSystemType } = useContext(ContextApp);
  return [systemType, setSystemType];
};

const useAuth = () => {
  const { auth } = useContext(ContextApp);
  return [auth];
};

const useTask = () => {
  const { selectedTask, setSelectedTask } = useContext(ContextApp);
  return [selectedTask, setSelectedTask];
};

/**
 * use this to compare your current system type
 @example
    import { useSystemType,enumSystemTypes } from "../../../core/providers/provider.app";
    const [systemType, setSystemType] = useSystemType();

    if(systemType===enumSystemTypes.admin){}
 */
const enumSystemTypes = {
  admin: 'admin',
  referrer: 'referrer',
  'referrer-admin': 'referrer-admin',
  clinician: 'clinician',
  patient: 'patient'
};

export { useAuth, useTask, useSystemType, enumSystemTypes };
