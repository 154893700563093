import { useForm, useWatch } from 'ALIASED-antd/lib/form/Form';

import { Form } from 'ALIASED-antd';
export { Form };
export { AsyncAntdButton as Button } from '../src/components/AsyncComponents';
export { WrappedAntdSelect as Select } from '../src/components/WrappedAntdSelect/WrappedAntdSelect';
export {
  Alert,
  Divider,
  Input,
  Modal,
  Radio,
  Space,
  Spin,
  Row,
  Col,
  Table,
  Tooltip,
  Card,
  Empty,
  Descriptions,
  Dropdown,
  Avatar,
  Menu,
  Checkbox,
  Comment,
  List,
  DatePicker,
  Typography,
  AutoComplete,
  InputNumber,
  Carousel,
  Result,
  Upload,
  Collapse,
  Switch,
  Skeleton,
  Grid,
  TimePicker,
  Image,
  Steps,
  Tabs,
  Layout,
  Progress,
  Badge,
  notification,
  Tag,
  Rate,
  Drawer,
  PageHeader,
  Popover,
  Cascader
} from 'ALIASED-antd';

import Column from 'ALIASED-antd/lib/table/Column';
export { Column };
import Link from 'ALIASED-antd/lib/typography/Link';
export { Link };
const useFormInstance = Form.useFormInstance;

export { useForm, useFormInstance, useWatch };

export type {
  FormProps,
  FormItemProps,
  AlertProps,
  ButtonProps,
  CardProps,
  MenuProps,
  InputRef,
  FormInstance,
  ModalFuncProps,
  ModalProps,
  ResultProps,
  TableProps,
  TableColumnType,
  InputProps,
  UploadFile,
  SelectProps,
  RadioChangeEvent
} from 'ALIASED-antd';

export type { SorterResult, ColumnsType } from 'ALIASED-antd/lib/table/interface';
export type { TabsProps } from 'ALIASED-antd/lib/tabs';
export type { RcFile } from 'ALIASED-antd/lib/upload/interface';
export type { Rule, RuleObject } from 'ALIASED-antd/lib/form';

export { FormProvider } from 'ALIASED-antd/lib/form/context';

export type { FilterValue } from 'ALIASED-antd/lib/table/interface';
export type { CheckboxChangeEvent } from 'ALIASED-antd/lib/checkbox';
