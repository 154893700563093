import ServiceApi from 'vl-common/src/services/service.api';

export const CONFIG_TOOL_OPTIONS = [
  ['1', 'Standard'],
  ['2', 'Confluence'],
  ['3', 'Devops'],
  ['4', 'Templates']
] as const;

type GetConfigParams = {
  env_code: string;
  client_code: string;
  option: typeof CONFIG_TOOL_OPTIONS[number][0];
};

const ApiConfigTool = {
  getConfig: async ({ env_code, client_code, option }: GetConfigParams) => {
    const response = await ServiceApi.get(`/configtool/${env_code}/${client_code}/${option}`);
    return response.data;
  }
};

export default ApiConfigTool;
