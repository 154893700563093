import { useCallback, useContext, useEffect, useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { Auth } from 'aws-amplify';
import { Har, Request, Entry } from 'har-format';
import { useSystemType, enumSystemTypes } from 'vl-common/src/context/provider.app';
import ContextApp, { AdminContext } from 'vl-common/src/context/context.app';
import { useRouter } from 'vl-core/useRouter';
import ServiceApi from 'vl-common/src/services/service.api';
import { Config, setRuntimeConfig } from 'vl-common/src/hooks/Runtime';
import { useHarReady } from 'vl-common/src/hooks/useHARReady';
import { clearHARserviceWorker } from 'vl-common/src/lib/manageHARserviceWorker';

const getHeaderValue = (r: Request, header: string) =>
  r.headers?.find(({ name }) => name.toLowerCase() === header.toLowerCase())?.value;

function getReferrer(e: Entry) {
  const referrer = getHeaderValue(e.request, 'x-api-key') || getHeaderValue(e.request, 'referer');

  if (referrer) {
    const path = referrer.slice(new URL(referrer).origin.length);

    if (path.slice(1)) {
      return referrer;
    }
  }

  return '';
}

function makeKeysUpperCase(config: any) {
  return Object.fromEntries(Object.entries(config).map(([key, value]) => [key.toUpperCase(), value]));
}

function updateRuntimeConfig(har: Har) {
  const settingsEntry = har.log.entries.find(
    (e) => e.request.url.includes('/triage-settings/') && e.response.content.text
  );

  if (settingsEntry) {
    const settingsResponseString = settingsEntry.response.content.text;

    if (settingsResponseString) {
      const settings = makeKeysUpperCase(JSON.parse(settingsResponseString));

      setRuntimeConfig(settings as Config);
      console.log('Runtime config updated to:', settings);
    }
  }
}

async function navigateToFirstPage(
  sw: ServiceWorker,
  har: Har,
  harfile: undefined | null | string,
  setReady: () => void,
  type: keyof typeof enumSystemTypes,
  ctx: AdminContext,
  router: {
    push: (route: string) => Promise<void>;
    replace: (route: string) => void;
  },
  navigateTo = ''
) {
  const { pathname: origPathname, hash: originHash, href: originHref } = window.location;
  if (sw && har && har.log) {
    const firstUsersUserUrl = har.log.entries.find((e) => e.request.url.includes('/users/user/'))?.request.url;

    if (!firstUsersUserUrl) throw Error('Could not determine HAR test user');

    const pages = har.log.entries
      .map((e) => getReferrer(e))
      .filter((s) => s)
      .filter((e, i, l) => e && l.indexOf(e) === i)
      .filter((page) => page.includes(navigateTo)) as string[];
    const [, APPUSER] = /\/users\/user\/([0-9a-f-]*)/.exec(firstUsersUserUrl)!;
    const earliestTime = Math.min(...har.log.entries.map(({ startedDateTime }) => new Date(startedDateTime).getTime()));
    const startedDateTime = !Number.isNaN(earliestTime) ? new Date(earliestTime) : new Date();
    const originSearch = new URLSearchParams(window.location.search);
    console.log(`Found user (${APPUSER}). Attempting to use HAR file ${harfile}`);
    // @ts-ignore
    Auth.authenticatedUser = APPUSER;
    Auth.isInHARMode = true;

    console.log('The following HAR routes were included:');
    pages.forEach((value) => console.log(' - ', value));

    console.log('Adjusting time:', startedDateTime, earliestTime);

    sessionStorage.setItem('mocked', 'true');

    // Fixme: timemachine horribly updates the time *on import*! Unconditionally importing the module breaks ReactQuery
    // Fixme: and this async import means that there can be problems with race conditions in the copyright notice in the
    //  footer sometimes displaying the wrong year.
    //
    await new Promise<void>((resolve) =>
      import('timemachine').then(({ default: timeMachine }) => {
        timeMachine.config({ dateString: new Date(startedDateTime).toISOString() });
        // trigger a global re-render
        ctx.setAuth({ ...ctx.auth });

        // @ts-ignore
        globalThis.GlobalStore = {
          auth: { lang_code: 'en-GB', timezone: 'Europe/London' }
        };

        resolve();
      })
    );

    updateRuntimeConfig(har);

    if (
      originSearch.has('har') ||
      origPathname.startsWith('/login') ||
      originHash.startsWith('#/account/login') ||
      sessionStorage.getItem('mocked') ||
      sessionStorage.getItem('har')
    ) {
      const firstPage = pages[0];

      if (!firstPage) {
        console.error('Could not determine first page');
        return;
      }
      const desiredPage = firstPage;

      if (type === 'admin') {
        const { hash: desiredHash } = new URL(desiredPage);
        const [, originSearch] = originHref.split('#')[1].split('?');
        if (!ctx.auth.isAuthenticated) {
          // @ts-ignore
          ctx.setAuth({ ...ctx.auth, authentication: false, reload: true });
          const destination = `${desiredHash.replace('#', '')}?${originSearch}`;
          router.push(destination);
        }
      } else {
        const { pathname, search } = new URL(desiredPage);
        router.push(`${pathname}${search}`);
      }
    }
  } else if (!har && sessionStorage.getItem('har') && sessionStorage.getItem('mocked')) {
    if (type === 'admin') {
      const [hash] = originHref.split('#')[1].split('?');
      router.push(`${hash.replace('#', '')}?har=har/${sessionStorage.getItem('har')}`);
    } else {
      router.push(`/${type}/?har=har/${sessionStorage.getItem('har')}`);
    }
  }
  setReady();
}

export function useHAR(getDynamicConfig: () => Promise<any>) {
  const ctx = useContext(ContextApp);
  const [harfile] = useQueryParam('har', StringParam);
  const [navigateTo] = useQueryParam('nav', StringParam);
  const router = useRouter();
  const [har, setHar] = useState<Har>();
  const [sw, setSw] = useState<ServiceWorker>();
  const [inTestingMode, setInTestingMode] = useState(sessionStorage.getItem('mocked') === 'true');
  const [inRecordingMode, setInRecordingMode] = useState(sessionStorage.getItem('recording') === 'true');
  const [dumpedHAR, setDumpedHAR] = useState(null);
  const [type] = useSystemType();
  const { setReady, isReady } = useHarReady();

  useEffect(() => {
    if (!harfile) setReady();
  }, [harfile, setReady]);

  const registerServiceWorker = async () => {
    await navigator.serviceWorker
      ?.register('/sw.js')
      .then((registration) => {
        registration.update().then();
      })
      .catch((err) => {
        console.error('Failed to register sw.js. Have you built the service-worker directory?', err);
      });
  };

  const connectToSw = async () => {
    navigator.serviceWorker?.addEventListener('message', async (e) => {
      if (e.data.har) {
        setHar(e.data.har);
      }

      if (e.data.dump) {
        setDumpedHAR(e.data.dump);
      }

      if (e.data.recording) {
        console.log('Recording started');
      }
    });

    const swr = await navigator.serviceWorker?.ready;

    if (swr?.active) {
      const sw = swr.active;

      setSw(sw);
    }

    return swr?.active;
  };

  const registerAndConnect = async () => {
    await registerServiceWorker();

    return connectToSw();
  };

  useEffect(() => {
    if (harfile && !sw) {
      registerAndConnect().then();
    }
  }, [harfile]);

  useEffect(() => {
    if (harfile && sw) {
      sw.postMessage({ playback: true, harfile, transcript: true });
    }
  }, [harfile, sw]);

  useEffect(() => {
    if (!sw && (inRecordingMode || inTestingMode)) {
      registerAndConnect().then();
    }
  }, [sw, inRecordingMode, inTestingMode]);

  const record = useCallback(async (): Promise<void> => {
    const worker = sw || (await registerAndConnect());

    worker?.postMessage({ record: true });

    // arrange for the triage-settings to appear in the HAR
    return new Promise((resolve) => {
      getDynamicConfig()
        .then(() => resolve())
        .catch(() => resolve());
    });
  }, [sw]);

  const dump = useCallback(async () => {
    const worker = sw || (await registerAndConnect());

    await getDynamicConfig();
    // and try to load the user record
    await ServiceApi.getCurrentUser().catch();

    worker?.postMessage({ dump: true });
  }, [sw]);

  const clear = useCallback(async () => {
    if (!sw) {
      await registerAndConnect();
    }
    sw?.postMessage({ clear: true });
  }, [sw]);

  useEffect(() => {
    if (sw && har && !isReady) {
      navigateToFirstPage(sw, har, harfile, setReady, type, ctx, router as any, navigateTo || undefined).then();
    }
  }, [har, sw, isReady]);

  return {
    har,
    record,
    dump,
    dumpedHAR,
    clear,
    inTestingMode,
    setInTestingMode,
    inRecordingMode,
    setInRecordingMode
  };
}

/** Reset, in particular, the lastPostingDate in the service worker since it caries over between refreshes */
export function resetHARServiceWorkerAsRequired() {
  if (typeof navigator !== 'undefined') {
    console.log('Service Worker on startup', navigator.serviceWorker?.controller);
    if (process?.env?.HAR_ENABLED === 'false') {
      clearHARserviceWorker();
    } else {
      if (navigator.serviceWorker?.controller) {
        const worker = navigator.serviceWorker.controller;

        worker.postMessage({ reset: true });
      }
    }
  }
}

export default useHAR;
