import ServiceApi from '../services/service.api';

const baseServiceUrl = `/providers`;

const ApiProviders = {
  getServiceProviderGroups: (params) => {
    const url = `${baseServiceUrl}/hospital/groups/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  getServiceProviderSites: (params) => {
    const url = `${baseServiceUrl}/hospitals/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  getServiceImagingProviders: (params) => {
    const url = `${baseServiceUrl}/imgprovs/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  getServiceProviders: (params) => {
    const url = `${baseServiceUrl}/service/providers/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  upsertSiteFilterTags: ({ client_id, hospital_id, service_cat_code, filter_tags }) => {
    const url = `/tags/hospital`;
    return ServiceApi.post(url, {
      client_id,
      hospital_id,
      service_cat_code,
      filter_tags
    });
  },
  updateServiceProviderEnabled: ({ client_id, service_cat_code, hospital_id, enabled }) => {
    const url = `${baseServiceUrl}/service/provider/enable`;
    return ServiceApi.post(url, {
      user_guid: ServiceApi.userGuid,
      client_id,
      service_cat_code,
      hospital_id,
      enabled
    });
  },
  UpsertGroup: (data) => {
    const url = `${baseServiceUrl}/hospital/group`;
    const body = {
      user_guid: ServiceApi.userGuid,
      ...data
    };
    console.debug('UPSERT GROUP PRE-BODY:', body);
    return ServiceApi.post(url, body);
  },
  UpsertSite: (data) => {
    const url = `${baseServiceUrl}/hospital`;
    const body = {
      user_guid: ServiceApi.userGuid,
      ...data
    };
    console.debug('UPSERT SITE PRE-BODY:', body);
    return ServiceApi.post(url, body);
  },
  InsertingImagingProvider: (data) => {
    const url = `${baseServiceUrl}/imgprov`;
    const body = {
      user_guid: ServiceApi.userGuid,
      ...data
    };
    console.debug('INSERT IMAGING-PROVIDER PRE-BODY:', body);
    return ServiceApi.post(url, body);
  },
  AddPrivider: (data) => {
    const url = `${baseServiceUrl}/hospital/service/cat/`;
    const body = {
      user_guid: ServiceApi.userGuid,
      ...data
    };
    console.debug('INSERT IMAGING-PROVIDER PRE-BODY:', body);
    return ServiceApi.post(url, body);
  },
  AddServices: (data) => {
    const url = `${baseServiceUrl}/hospital/service/type/`;
    const body = {
      user_guid: ServiceApi.userGuid,
      ...data
    };
    console.debug('INSERT IMAGING-PROVIDER PRE-BODY:', body);
    return ServiceApi.post(url, body);
  },
  disableImagingProvider: (target_imgProvider_guid = '') => {
    const url = `${baseServiceUrl}/imgprov/disable`;
    const body = {
      user_guid: ServiceApi.userGuid,
      enabled: false, // disabled
      hospital_id: target_imgProvider_guid
    };
    console.debug('DISABLE IMAGING-PROVIDER PRE-BODY:', body);
    return ServiceApi.post(url, body);
  }
};

export default ApiProviders;
