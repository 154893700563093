import assert from 'assert';
import Auth from 'aws-amplify';

/** returns true if on localhost or ngrok */
export const getIsLocalhostOrNgrok = (): boolean => {
  const isLocalhost = window.location.hostname === 'localhost';
  const isNgrok = window.location.hostname?.includes('ngrok');
  return isLocalhost || isNgrok;
};

/** returns a string URL (with explicit origin) of "path" relative to "originURL",
 *  including the "story segment" if currently on one */
export function redirectToLoginURL(originURL: string, path: string) {
  // This will not redirect to a different hostname for localhost or ngrok as these need specific hostnames
  const isLocalhostOrNgrok = getIsLocalhostOrNgrok();
  // not including the slashes at the start 'https://... ;-)
  const removeDoubleSlashes = (url: string) => url.replaceAll(/(?<!:)(\/\/)/g, '/');

  if (isLocalhostOrNgrok || !originURL) {
    return removeDoubleSlashes(`${window.location.origin}/${path}`);
  }

  // This will keep the path to the story build for story deployments
  const firstPathSegment = window.location.pathname?.split('/')[1];

  if (firstPathSegment) {
    const [project, ...rest] = firstPathSegment.split('-');

    if (project.startsWith('vl') && rest.length > 1) {
      const isStoryDeployment = ['vlaxa', 'vlnhs', 'vlm', 'vlt', 'vls'].includes(project);

      if (isStoryDeployment) {
        return removeDoubleSlashes(`${originURL}/${firstPathSegment}/${path}`);
      }
    }
  }

  // This will redirect to the correct hostname for all non-testing builds
  return removeDoubleSlashes(`${originURL}/${path}`);
}

// Function to shift characters
function shiftString(str: string, shiftBy: number) {
  return str
    .split('')
    .map((char) => String.fromCharCode(char.charCodeAt(0) + shiftBy))
    .join('');
}

/** Browser redirect to the given URL passing Cognito auth in the hash fragment
 *
 * NB: Cognito auth is scoped per Cognito user pool, so auth can only meaningfully be transferred
 * between domains *in the same VL environment*. Tokens issues in DEV will not work in PROD, or vice
 * versa. But tokens issued against (say) ndevapp will work with ndevclin because they share the same
 * (DEV) Cognito identity pool.
 * */
export default async function saveAuthAndRedirect(login_url: string, vlAdmin = false) {
  assert(
    Auth.currentSession().then(
      () => true,
      () => false
    ),
    'Cannot saveAuthAndRedirect() if not authenticated'
  );
  const cognitoSession: Record<string, any> = {};

  if ( !login_url ) {
    return
  }

  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);

    if (
      key &&
      ['accessToken', 'clockDrift', 'idToken', 'refreshToken', 'userData', 'LastAuthUser'].find((p) => key.endsWith(p))
    ) {
      cognitoSession[shiftString(key, 3)] = sessionStorage.getItem(key);
    }
  }

  // navigate to the correct URL (which should then pick up the auth automagically)
  const target = redirectToLoginURL(login_url, vlAdmin ? '#/account/login/' : 'login#');
  const hash = encodeURI(JSON.stringify(cognitoSession));

  console.log(`Redirecting to ${target} with ${hash.length} of hash`);
  window.location.replace(`${target}${hash}`);
}
