export function clearHARserviceWorker() {
  navigator?.serviceWorker?.getRegistrations()?.then(async (registrations) => {
    const harRegistrations = registrations?.filter((registration) =>
      registration?.active?.scriptURL?.includes('/sw.js')
    );
    await Promise.allSettled(harRegistrations?.map((registration) => registration.unregister()));
    if (harRegistrations?.length !== 0) {
      window.location.reload();
    }
  });
}

export function harServiceWorkerIsRunning(callback: Function) {
  navigator?.serviceWorker?.getRegistrations()?.then((registrations) => {
    callback(registrations?.filter((registration) => registration?.active?.scriptURL?.includes('/sw.js'))?.length > 0);
  });
}
