import React, { useState } from 'react';
import { Modal, Button, Row, Input } from 'antd';
import * as HAR from 'har-format';

interface DumpModalProps {
  visible: boolean;
  onCancel: () => void;
  har?: HAR.Har;
}

const DumpModal = ({ visible, onCancel, har }: DumpModalProps) => {
  const [filename, setFilename] = useState('');

  const onDownload = () => {
    const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(har, null, 2));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', `${filename || 'new'}.har`);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  return (
    <Modal open={visible} onCancel={onCancel} footer={[]}>
      <>
        <h3 className="title">
          <span>Dump HAR file</span>
        </h3>
        <Row>
          Please confirm the filename to download to:
          <Input
            type="text"
            autoComplete="off"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFilename(e.target.value);
            }}
          />
        </Row>
        <Row style={{ width: '100%', marginTop: '16px' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button
              key="close"
              style={{ marginRight: '8px' }}
              onClick={() => {
                onCancel();
              }}
            >
              Close
            </Button>
            <Button key="download-har" type="primary" onClick={onDownload}>
              Download HAR
            </Button>
          </div>
        </Row>
      </>
    </Modal>
  );
};

export default DumpModal;
