import ServiceApi from '../services/service.api';

const baseServiceUrl = `/meta`;

const ApiMeta = {
  getTaskMeta: () => {
    const url = `${baseServiceUrl}/tasks/${ServiceApi.userGuid}`;
    return ServiceApi.get(url);
  },
  getTaskMeta2: () => {
    const url = `${baseServiceUrl}/tasks2/${ServiceApi.userGuid}`;
    return ServiceApi.get(url);
  },
  getSiteMeta: (params = {}) => {
    const url = `${baseServiceUrl}/hospital/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  getClinicianMeta: (params = {}) => {
    const url = `${baseServiceUrl}/clinicians/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  getInvreqMeta: (params) => {
    const url = `${baseServiceUrl}/invreq/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  getUserAppointmentMeta: (params = {}) => {
    const url = `${baseServiceUrl}/appointments/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  getUsersMetadata: (userGuid = ServiceApi.userGuid, params = {}) => {
    const url = `${baseServiceUrl}/users/${userGuid}`;
    return ServiceApi.get(url, { params });
  },
  getUsersCaseMeta: (caseId) => {
    const url = `${baseServiceUrl}/case/${ServiceApi.userGuid}${caseId ? `?case_id=${caseId}` : ''}`;
    return ServiceApi.get(url);
  },
  getCasePriorities: ({ pat_guid, speccat, specarea }) => {
    const url = `${baseServiceUrl}/case/${ServiceApi.userGuid}`;
    const params = { pat_guid, speccat, specarea };
    return ServiceApi.get(url, { params });
  },
  GetReasons: () => {
    const url = `${baseServiceUrl}/gdpr/${ServiceApi.userGuid}`;
    return ServiceApi.get(url);
  },
  // returns { meta: [ { languages: [ ... ], info_tags: [ ... ], filter_tags: [ ... ] } ] }
  getMetaMisc: (patientGuid = null) => {
    const patientRoute = patientGuid ? `/${patientGuid}` : '';
    const url = `${baseServiceUrl}/misc/${ServiceApi.userGuid}${patientRoute}`;
    return ServiceApi.get(url);
  },
  getSurveyValidators: () => {
    const url = `${baseServiceUrl}/misc/${ServiceApi.userGuid}?rule_code=ALL`;
    return ServiceApi.get(url);
  },
  getConfigToolMeta: () => {
    const url = `${baseServiceUrl}/configtool/${ServiceApi.userGuid}`;
    return ServiceApi.get(url);
  },
  getEligibilityPolicies: (caseId = undefined) => {
    const params = { case_id: caseId };
    const url = `${baseServiceUrl}/case/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  refdata: () => {
    const url = `${baseServiceUrl}/refdata/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, {});
  },
  refdataTypes: () => {
    const url = `${baseServiceUrl}/refdata/appt/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, {});
  },
  getOutputs: (templateCode) => {
    const url = `${baseServiceUrl}/outputs/${ServiceApi.userGuid}/${templateCode}`;
    return ServiceApi.get(url);
  },
  patientsCaseMetaData: (id) => {
    const url = `${baseServiceUrl}/case/${id}`;
    return ServiceApi.get(url, {});
  },
  getCaseRefValidation: (patientGuid) => {
    const url = `${baseServiceUrl}/misc/${ServiceApi.userGuid}/${patientGuid}?rule_code=CLIENT_CASE_ID`;
    return ServiceApi.get(url, {});
  }
};

export default ApiMeta;
