import { Auth as AWSAuth, Amplify as AWSAmplify } from 'ALIASED-aws-amplify';
import type { API as AWSApi } from 'ALIASED-aws-amplify';
import ky, { KyResponse, Options, Input } from 'ky';
import { getRuntimeConfig } from '../src/hooks/Runtime';

const apiClient = ky.extend({
  timeout: false,
  retry: {
    statusCodes: [429],
    limit: 3
  },
  hooks: {
    beforeRequest: [
      async (request, options) => {
        if (request.method === 'GET') {
          request.headers.set('X-Api-Key', window.location.toString());
        }
      }
    ]
  }
});

const noRetryApiClient = apiClient.extend({
  retry: {
    statusCodes: [],
    limit: 0
  },
});

function chooseClient() {
  return getRuntimeConfig().DISABLED_EXPONENTIAL_BACKOFF ? noRetryApiClient : apiClient;
}

async function unpackResponse(r: Response) {
  if (r.status === 204) {
    return '';
  }

  const text = await r.text();

  if (!text) return [];

  try {
    return JSON.parse(text);
  } catch (_) {
    return text;
  }
}

async function makeRequest(
  endpoint: string,
  path: string,
  init: Record<string, any>,
  callback: (url: Input, options?: Options) => Promise<KyResponse>
) {
  if (!Auth.authenticatedUser) {
    init.headers = init.headers || {};

    init.headers['X-Api-Key'] = window.location.toString();

    if (!init.noAuth) {
      const currentSession = await Auth.currentSession();
      init.headers.Authorization = `Bearer ${currentSession.getIdToken().getJwtToken()}`;
    }
  }

  // translate to a fetch call in order to hit service worker in HAR mode
  const qs = Object.fromEntries(
    Object.entries(init.queryStringParameters || {}).map(([key, value]) => [key, value ?? ''] as [string, string])
  );
  const sp = new URLSearchParams(qs).toString();
  const res = await callback(`https://${endpoint}api.virtuallucyweb.co.uk/v1${path}${sp ? `?${sp}` : ''}`, {
    body: init.body ? JSON.stringify(init.body) : null,
    headers: init.headers
  }).catch(async (e) => {
    if (e.response) {
      const res = e.response;

      res.data = await unpackResponse(res.clone()).catch();
    }
    throw e;
  });

  if (!res.ok) {
    const e = new Error(`Request failed with status ${res.status}`) as any;

    e.response = {
      status: res.status,
      statusText: res.statusText,
      data: await res.json().catch()
    };

    throw e;
  }
  const data = await unpackResponse(res);
  return { data, status: res.status, statusText: res.statusText };
}

export const API: {
  get: typeof AWSApi.get;
  post: typeof AWSApi.post;
  put: typeof AWSApi.put;
} = {
  get: async (endpoint, path, init = {}) => {
    return makeRequest(endpoint, path, init, chooseClient());
  },
  post: async (endpoint, path, init = {}) => {
    return makeRequest(endpoint, path, init, chooseClient().post);
  },
  put: async (endpoint, path, init = {}) => {
    return makeRequest(endpoint, path, init, chooseClient().put);
  }
};

export const Amplify: {
  Logger: { LOG_LEVEL: number };
  configure: typeof AWSAmplify.configure;
} = {
  Logger: { LOG_LEVEL: 0 },
  configure: (...config) => {
    if (!Auth.authenticatedUser) return AWSAmplify.configure(...config);
    return {};
  }
};

export const Hub = {
  listen: (event: 'auth', callback: () => {}) => {}
};

// @ts-ignore
export const Auth = {
  authenticatedUser: '',

  isInHARMode: false,

  mockedAuth: true,

  currentAuthenticatedUser: (options: { bypassCache: true }) => {
    if (!Auth.authenticatedUser) return AWSAuth.currentAuthenticatedUser(options);
    return Promise.resolve({ email: 'dummy-har@mailinator.com' });
  },

  signOut: (...args: Parameters<typeof AWSAuth.signOut>) => {
    if (!Auth.authenticatedUser) return AWSAuth.signOut(...args);
    return Promise.resolve({});
  },

  signIn: (...args: Parameters<typeof AWSAuth.signIn>) => {
    if (!Auth.authenticatedUser) {
      return AWSAuth.signIn(...args);
    }
    return Promise.resolve({
      challengeParam: { requiredField: 'token' },
      challengeName: 'CUSTOM_CHALLENGE'
    });
  },
  federatedSignIn: (...args: Parameters<typeof AWSAuth.federatedSignIn>) => {
    if (!Auth.authenticatedUser) return AWSAuth.federatedSignIn(...args);
    return Promise.resolve({});
  },
  sendCustomChallengeAnswer: (...args: Parameters<typeof AWSAuth.sendCustomChallengeAnswer>) => {
    if (!Auth.authenticatedUser) {
      return AWSAuth.sendCustomChallengeAnswer(...args);
    }
    return Promise.resolve({
      challengeParam: { requiredField: 'code2' },
      authenticationFlowType: 'USER_SRP_AUTH'
    });
  },
  currentSession: async (...args: Parameters<typeof AWSAuth.currentSession>) => {
    if (!Auth.authenticatedUser) {
      return AWSAuth.currentSession(...args);
    }

    return Promise.resolve({
      accessToken: { payload: { exp: 0 } },
      getIdToken: () => ({
        payload: { sub: Auth.authenticatedUser },
        getJwtToken: () => 'dummy-token'
      })
    });
  },

  configure: (...args: Parameters<typeof AWSAuth.configure>) => {
    if (!Auth.authenticatedUser) return AWSAuth.configure(...args);
    return {};
  }
};

export default Auth;
