/*
 There's no proper global state that can be accessed outside of React in the Admin portal, so this bit of
 global state is handled by this JavaScript module.
 */

let { locale: g_locale, timeZone: g_timeZone } = Intl.DateTimeFormat().resolvedOptions(); // e.g. en-GB, Europe/London

export function setLocalisation(locale: string, timezone: string) {
  g_locale = locale;
  g_timeZone = timezone;
}

export function getLocalisation() {
  return { g_locale, g_timeZone };
}
