import ServiceApi from '../services/service.api';
import { tools } from '../lib/service.tools';
import qs from '../lib/query-string';

const baseServiceUrl = ``;

const ApiFile = {
  getAvatar: (params = {}) => {
    params.ticks = new Date().toISOString() + '/' + tools.bigRandomInteger();
    const url = `${baseServiceUrl}/avatar`;
    return ServiceApi.get(url, { params });
  },
  getSignature: (params = {}) => {
    params.ticks = new Date().toISOString() + '/' + tools.bigRandomInteger();
    const url = `${baseServiceUrl}/signature`;
    return ServiceApi.get(url, { params });
  },
  postAvatar: (input) => {
    const url = `${baseServiceUrl}/avatar`;
    const body = {
      user_guid: ServiceApi.userGuid,
      ...input
    };
    return ServiceApi.post(url, body);
  },
  postSignature: (input) => {
    const url = `${baseServiceUrl}/signature`;
    const body = {
      user_guid: ServiceApi.userGuid,
      ...input
    };
    return ServiceApi.post(url, body);
  },

  pdfGen: (body, params) => {
    const query_ = qs.stringify(params);
    const url = `${baseServiceUrl}/pdfgen?${query_}`;
    return ServiceApi.post(url, body);
  },

  pdfValidate: (body) => {
    const url = `${baseServiceUrl}/pdf-validate`;
    return ServiceApi.post(url, body);
  },

  uploadDocsToDatabase: (body) => {
    const { case_id, appt_id, patient_guid, ...copiedBody } = body;
    const caseOrAppointment = appt_id ? 'appt' : 'case';
    const ident = caseOrAppointment === 'case' ? { case_id } : { appt_id };

    return ServiceApi.post(`${baseServiceUrl}/files/${caseOrAppointment}/files/upload`, {
      user_guid: ServiceApi.userGuid,
      ...copiedBody,
      ...ident
    });
  },

  getPreSignedS3Url: (user_guid, body) =>
    ServiceApi.get(`${baseServiceUrl}/file`, { params: { user_guid, ...body, tempurl: true } }),

  generatePreSignedS3Url: (body) => ServiceApi.post(`${baseServiceUrl}/file`, { ...body, tempurl: true }),

  fileHasBeenUploaded: (body) =>
    ServiceApi.post(`${baseServiceUrl}/file`, { user_guid: ServiceApi.userGuid, ...body, uploaded: true }),

  postcodeSearch: (postcode_name) => ServiceApi.get(`${baseServiceUrl}/postcode/${postcode_name}`, {}),

  postcode: (postcode_name) => ServiceApi.get(`${baseServiceUrl}/postcode/${postcode_name}`, {})
};

export default ApiFile;
