import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function AccountLayout({ children }) {
  return (
    <div>
      <Container className="vl-account-pages">
        <Row className="mt-5">
          <Col>
            <div className="d-flex text-left account-logo-title">
              <div className="brand-name">
                <span>HBS</span>
                <strong>UK</strong>
                &nbsp;
              </div>
              <div>Virtual Lucy</div>
            </div>
            <hr className="account-hr-login" />
          </Col>
        </Row>
        {children}
        <br />
        <br />
        <br />
      </Container>
    </div>
  );
}

export default AccountLayout;
