import ServiceApi from '../services/service.api';

const baseServiceUrl = `/clinicians`;
const ApiClinicians = {
  getAll: (params) => {
    const url = `${baseServiceUrl}/clinicians/${ServiceApi.userGuid}`;
    return new Promise((resolve, reject) => {
      ServiceApi.get(url, { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  getReferralClinicians: (params) => {
    const url = `${baseServiceUrl}/refclins/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  getInvreq: (params) => {
    const url = `${baseServiceUrl}/invreq/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  updateInvestigationProvider: (appt_id, hospital_id, img_type_code, ireq_id) => {
    return ServiceApi.post(`${baseServiceUrl}/invreq/provider`, {
      appt_id,
      hospital_id,
      img_type_code,
      ireq_id,
      user_guid: ServiceApi.userGuid
    });
  },
  getApptPifuData: (appt_id) => {
    return ServiceApi.get(`${baseServiceUrl}/appt/pifu/${ServiceApi.userGuid}/?appt_id=${appt_id}`);
  },
  saveApptPifuData: (params) => {
    const url = `${baseServiceUrl}/appt/pifu`;
    return ServiceApi.post(url, { ...params, user_guid: ServiceApi.userGuid });
  }
};

export default ApiClinicians;
