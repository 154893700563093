import ServiceApi from '../services/service.api';

const baseServiceUrl = `/referrers`;

const ApiReferrer = {
  AddMember: (input) => {
    const url = `${baseServiceUrl}/member`;

    const body = {
      ...input,
      user_guid: ServiceApi.userGuid
    };

    return ServiceApi.put(url, body);
  },
  getTriage: (params) => {
    const url = `${baseServiceUrl}/triage`;
    return new Promise((resolve, reject) => {
      ServiceApi.post(url, params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  search: (params) => {
    const url = `${baseServiceUrl}/patients/${ServiceApi.userGuid}`;
    return new Promise((resolve, reject) => {
      ServiceApi.get(url, { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  checkMemberExist: (email) => {
    const url = `${baseServiceUrl}/email/${ServiceApi.userGuid}`;
    const params = { email };
    return ServiceApi.get(url, { params }); //200 exist, 404 not exist
  },
  member_editProfile: (params) => {
    const url = `${baseServiceUrl}/member/profile`;
    const body = { user_guid: ServiceApi.userGuid, ...params };
    console.debug('EDIT MEMBER PRE-BODY', body);
    return ServiceApi.post(url, body);
  },
  resendInvitationEmail: (target_guid) => {
    const url = `${baseServiceUrl}/welcome/resend`;
    const body = {
      user_guid: ServiceApi.userGuid,
      resend_user_guid: target_guid
    };
    console.debug('RESEND INVITATION EMAIL PRE-BODY', body);
    return ServiceApi.put(url, body);
  },
  resendInvitationEmailNew: (guid) => {
    const url = `/resend-welcome?up_user_guid=${guid}`;
    return ServiceApi.get(url);
  },
  getAppointmentReferral: (params) => {
    const url = `${baseServiceUrl}/case/refer/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  getPatientInfo: (params) => {
    const url = `${baseServiceUrl}/patients/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  postAppointmentReferral: (params) => {
    const url = `${baseServiceUrl}/case/refer/`;
    const body = {
      user_guid: ServiceApi.userGuid,
      ...params
    };
    console.debug('post AppointmentRef PRE-BODY', body);
    return ServiceApi.post(url, body);
  },
  rejectReferral: (body) => {
    const url = `${baseServiceUrl}/case/refer/reject`;
    const params = { user_guid: ServiceApi.userGuid, ...body };
    return ServiceApi.post(url, params);
  },
  updateApptUbrn: (appt_id, ubrn) => {
    const url = `${baseServiceUrl}/appointment/ubrn`;
    const params = {
      user_guid: ServiceApi.userGuid,
      appt_id,
      ubrn
    };
    return ServiceApi.post(url, params);
  },
  checkUbrn: (ubrn, case_id) => {
    // basic validation on ubrns
    // returns 200 with ubrn on success
    // returns 404 without on failure
    const url = `/ers/referral/check?ubrn=${ubrn}&case_id=${case_id}&user_guid=${ServiceApi.userGuid}`;
    const params = {
      ubrn,
      case_id
    };
    return ServiceApi.get(url, params);
  }
};

export default ApiReferrer;
