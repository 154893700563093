import { getSettingsUrl } from 'vl-common/src/lib/getSettingsURL';

const getDynamicConfig = async () => {
  try {
    const response = await fetch(getSettingsUrl());
    const config = await response.json();
    const formattedConfig = makeKeysUpperCase(config);

    return { config: formattedConfig, response };
  } catch (error) {
    throw new Error(error);
  }
};

function makeKeysUpperCase(config) {
  return Object.fromEntries(Object.entries(config).map(([key, value]) => [key.toUpperCase(), value]));
}

export { getDynamicConfig, makeKeysUpperCase };
