import ServiceApi from 'vl-common/src/services/service.api';

const baseServiceUrl = `/audit`;

export interface AuditSummaryParams {
  start_date?: string;
  end_date?: string;
  pat_guid?: string;
  rows?: number;
  page?: number;
  order?: string;
}

export interface AuditDetailsParams {
  id: number;
  start_date?: string;
  end_date?: string;
  rows?: number;
  page?: number;
  order?: string;
}

const ApiAudit = {
  getAppointmentsAuditSummary: ({ ...params }: AuditSummaryParams) => {
    const url = `${baseServiceUrl}/appts/${ServiceApi.userGuid}/?filter=true`;
    return ServiceApi.get(url, { params });
  },
  getAppointmentsAuditDetails: ({ id: appt_id, ...params }: AuditDetailsParams) => {
    const url = `${baseServiceUrl}/appts/${ServiceApi.userGuid}/`;
    return ServiceApi.get(url, { params: { appt_id, ...params } });
  },
  getAppointmentsOutcomesSummary: ({ ...params }: AuditSummaryParams) => {
    const url = `${baseServiceUrl}/appt/outcomes/${ServiceApi.userGuid}/?filter=true`;
    return ServiceApi.get(url, { params });
  },
  getAppointmentsOutcomesDetails: ({ id: outcome_code, ...params }: AuditDetailsParams) => {
    const url = `${baseServiceUrl}/appt/outcomes/${ServiceApi.userGuid}/`;
    return ServiceApi.get(url, { params: { outcome_code, ...params } });
  },
  getTasksAuditSummary: ({ ...params }: AuditSummaryParams) => {
    const url = `${baseServiceUrl}/tasks/${ServiceApi.userGuid}/?filter=true`;
    return ServiceApi.get(url, { params });
  },
  getTasksAuditDetails: ({ id: task_id, ...params }: AuditDetailsParams) => {
    const url = `${baseServiceUrl}/tasks/${ServiceApi.userGuid}/`;
    return ServiceApi.get(url, { params: { task_id, ...params } });
  },
  getCasesAuditSummary: ({ ...params }: AuditSummaryParams) => {
    const url = `${baseServiceUrl}/cases/${ServiceApi.userGuid}/?filter=true`;
    return ServiceApi.get(url, { params });
  },
  getCasesAuditDetails: ({ id: case_id, ...params }: AuditDetailsParams) => {
    const url = `${baseServiceUrl}/cases/${ServiceApi.userGuid}/`;
    return ServiceApi.get(url, { params: { case_id, ...params } });
  },
  getUserAuditDetails: ({ id: user_guid, ...params }: AuditDetailsParams) => {
    const url = `${baseServiceUrl}/user/${ServiceApi.userGuid}/`;
    return ServiceApi.get(url, { params: { user_guid, order: 'asc', ...params } });
  }
};

export default ApiAudit;
