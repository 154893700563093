import React, { useMemo, useState } from 'react';
import { Select } from 'ALIASED-antd';
import type { SelectProps as AntdSelectProps } from 'ALIASED-antd';

interface BaseOptionType {
  disabled?: boolean;
  [name: string]: any;
}

interface DefaultOptionType extends BaseOptionType {
  label: React.ReactNode;
  value?: string | number | null;
  children?: Omit<DefaultOptionType, 'children'>[];
}

type ArrayElementType<T> = T extends (infer E)[] ? E : T;

interface WrappedSelectProps<ValueType, OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType>
  extends AntdSelectProps<ValueType, OptionType> {
  'data-testid': string;
}

const mergePropOptions = (options: WrappedSelectProps<any, BaseOptionType>['options']) => {
  if (!options) return [];

  return options.flatMap((option) => {
    if ('options' in option && Array.isArray(option.options)) {
      return option.options;
    }
    return option;
  });
};

const mergeChildrenOptions = <OptionType extends BaseOptionType | DefaultOptionType>(
  children: React.ReactNode
): OptionType[] => {
  if (!children) return [];

  const processChild = (child: React.ReactNode) => {
    if (!React.isValidElement(child)) return [];

    const { type, props } = child;

    if (type === Select.Option) {
      return [{ value: props.value, label: props.children as string }];
    }

    if (type === Select.OptGroup) {
      const { children: optGroupChildren } = props;

      if (!optGroupChildren) return [];

      return optGroupChildren.flatMap(processChild);
    }

    return [];
  };

  const childrenArray = Array.isArray(children) ? children : [children];
  return childrenArray.flatMap(processChild);
};

export const WrappedAntdSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType
>(
  props: WrappedSelectProps<ValueType, OptionType>
) => {
  const [internalValue, setInternalValue] = useState<ValueType>();

  const mergedOptions = useMemo(() => {
    if (props.options) {
      // extract and merge options from options props passed in form of array of options and option groups
      return mergePropOptions(props.options);
    }

    if (props.children) {
      // Extract and merge options from Option and OptGroup components if children are provided
      return mergeChildrenOptions(props.children);
    }

    return [];
  }, [props.children, props.options]);

  const simulateTestClick = (value: ValueType, option: OptionType) => {
    return () => {
      if (!props.value && !props.onChange && !props.onSelect) {
        setInternalValue(value);
      }
      props.onChange && props.onChange(value, option);
      props.onSelect && props.onSelect(value as ArrayElementType<ValueType>, option);
    };
  };

  const valueSource = useMemo(() => {
    return props.value !== undefined ? props.value : internalValue;
  }, [props.value, internalValue]);

  return (
    <>
      <Select {...props} value={valueSource} style={{ width: '100%', ...props.style }} />
      {/* Hidden markup for automation tests */}
      <div data-select-value-map={props['data-testid']} className="d-none">
        {mergedOptions?.map((option) => (
          <div
            key={option.value}
            data-value={option.value}
            data-label={option.label}
            onClick={simulateTestClick(option.value, option)}
          />
        ))}
      </div>
    </>
  );
};

WrappedAntdSelect.Option = Select.Option as unknown as React.FC<any>;
WrappedAntdSelect.OptGroup = Select.OptGroup as unknown as React.FC<any>;
