import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const TermsAndConditionsModal = (props) => {
  const { close, hideAcceptButton } = props;

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(false);
    close();
  };

  useEffect(() => {
    setModal(props.show || false);
  }, [props.show]);

  return (
    <div>
      <Modal
        centered
        className="account-roles-modal"
        style={{ maxWidth: 1000 }}
        size={'lg'}
        isOpen={modal}
        toggle={toggle}
        backdrop={'static'}
        keyboard={true}
      >
        <ModalHeader toggle={toggle}>
          <span style={{ fontWeight: 700, fontSize: 30, color: '#DB6E20' }}>
            Terms <span style={{ color: '#636466' }}>and Conditions</span>
          </span>
          <span
            className="float-right"
            style={{
              fontSize: 14,
              color: '#636466',
              marginTop: 4,
              marginRight: 50
            }}
          >
            <strong>Last Update : 27 / 03 / 2019</strong>
          </span>
        </ModalHeader>
        <ModalBody>
          <p style={{ fontSize: 14 }}>
            <strong>Introduction</strong>
            <br />
            In return for HBSUK allowing you access to this website, HBSUK imposes and requires you to accept the Terms.
            If you are not prepared to agree to these Terms, then HBSUK requires you to immediately leave this website.
            <br />
            <br />
            <strong>Introduction</strong>
            <br />
            In return for HBSUK allowing you access to this website, HBSUK imposes and requires you to accept the Terms.
            If you are not prepared to agree to these Terms, then HBSUK requires you to immediately leave this website.
            <br />
            <br />
            <strong>Introduction</strong>
            <br />
            In return for HBSUK allowing you access to this website, HBSUK imposes and requires you to accept the Terms.
            If you are not prepared to agree to these Terms, then HBSUK requires you to immediately leave this website.
            <br />
            <br />
            <strong>Introduction</strong>
            <br />
            In return for HBSUK allowing you access to this website, HBSUK imposes and requires you to accept the Terms.
            If you are not prepared to agree to these Terms, then HBSUK requires you to immediately leave this website.
            <br />
            <br />
            <strong>Introduction</strong>
            <br />
            In return for HBSUK allowing you access to this website, HBSUK imposes and requires you to accept the Terms.
            If you are not prepared to agree to these Terms, then HBSUK requires you to immediately leave this website.
            <br />
            <br />
            <strong>Introduction</strong>
            <br />
            In return for HBSUK allowing you access to this website, HBSUK imposes and requires you to accept the Terms.
            If you are not prepared to agree to these Terms, then HBSUK requires you to immediately leave this website.
            <br />
            <br />
            <strong>Introduction</strong>
            <br />
            In return for HBSUK allowing you access to this website, HBSUK imposes and requires you to accept the Terms.
            If you are not prepared to agree to these Terms, then HBSUK requires you to immediately leave this website.
            <br />
            <br />
          </p>
        </ModalBody>
        <ModalFooter className={classnames({ 'd-none': hideAcceptButton })}>
          <Button color="primary" className="btn-block" style={{ backgroundColor: '#DB6E20' }} onClick={toggle}>
            Accept
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TermsAndConditionsModal;
