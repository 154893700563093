import React from 'react';

export type AdminContext = {
  auth: {
    user: {
      addline1: string;
      addline2: string;
      addline3: string;
      addline4: string;
      appt_ical: string;
      auth_type: string;
      avatar_url: string;
      build_path: string;
      client_code: string;
      client_id: number;
      client_name: string;
      country: string;
      created_date: string | Date;
      dob: string | Date;
      email: string;
      email_validated: boolean;
      employer_code: string;
      enabled: boolean;
      first_name: string;
      geo_region: string;
      ident_prov_code: string;
      insured: boolean;
      job_title: string;
      lang_code: string;
      last_name: string;
      locked_date: string | Date;
      locked_mins_left: number;
      lockout_mins: number;
      login_url: string;
      lucy_id: string;
      membership_no: string;
      needs_card_details: boolean;
      needs_recent_card_details: boolean;
      nhs_client: boolean;
      physitrack_id: string;
      physitrack_link: string;
      policies_accepted: boolean;
      postcode: string;
      pref_notification: string;
      provider_name: string;
      provider_settings_url: string;
      proxies_available: boolean;
      proxy_id: number;
      proxy_session: boolean;
      proxy_user_guid: string;
      region: string;
      restricted: boolean;
      self_refer: boolean;
      self_reg_required: boolean;
      seranova_id: string;
      service_name: string;
      sex: string;
      stripe_id: string;
      tel_mobile_no: string;
      tel_other_no: string;
      tel_pref_no: string;
      tel_validated: boolean;
      timeout_mins: number;
      timezone: string;
      title: string;
      user_guid: string;
      user_role_code: string;
      user_subtype_code: string;
      user_type_code: string;
      validated: boolean;
    };
    isAuthenticated: boolean;
    authentication: boolean;
    reload: boolean;
  };
  setAuth: (auth: AdminContext['auth']) => void;
  referrerAppointment: {
    clinguId: number;
    date: string;
    caseId: number;
    type: string;
    clinicianSubType: string;
    appId: number;
  };
  isAuthenticated: boolean;
  logRocketSessionURL: string;
};

const ContextApp = React.createContext<AdminContext>({
  auth: {
    user: {
      addline1: null,
      addline2: null,
      addline3: null,
      addline4: null,
      appt_ical: null,
      auth_type: null,
      avatar_url: null,
      build_path: null,
      client_code: null,
      client_id: null,
      client_name: null,
      country: null,
      created_date: null,
      dob: null,
      email: null,
      email_validated: null,
      employer_code: null,
      enabled: null,
      first_name: null,
      geo_region: null,
      ident_prov_code: null,
      insured: null,
      job_title: null,
      lang_code: null,
      last_name: null,
      locked_date: null,
      locked_mins_left: null,
      lockout_mins: null,
      login_url: null,
      lucy_id: null,
      membership_no: null,
      needs_card_details: null,
      needs_recent_card_details: null,
      nhs_client: null,
      physitrack_id: null,
      physitrack_link: null,
      policies_accepted: null,
      postcode: null,
      pref_notification: null,
      provider_name: null,
      provider_settings_url: null,
      proxies_available: null,
      proxy_id: null,
      proxy_session: null,
      proxy_user_guid: null,
      region: null,
      restricted: null,
      self_refer: null,
      self_reg_required: null,
      seranova_id: null,
      service_name: null,
      sex: null,
      stripe_id: null,
      tel_mobile_no: null,
      tel_other_no: null,
      tel_pref_no: null,
      tel_validated: null,
      timeout_mins: null,
      timezone: null,
      title: null,
      user_guid: null,
      user_role_code: null,
      user_subtype_code: null,
      user_type_code: null,
      validated: null
    },
    isAuthenticated: false,
    authentication: false,

    // NB: the "sense" of this flag seems to be inverted.
    // When it is FALSE it causes the routing to navigate back to the login page (see http://localhost:63342/api/file/src/App.js:277:31)
    // - it seems to be set here: http://localhost:63342/api/file/src/ui/views/Account/Login.js:242:5
    // - it seems to be reset in several places, for instance on logout here: http://localhost:63342/api/file/src/ui/containers/AppLayout/AppLayout-Admin.js:193:25
    reload: false
  },
  setAuth: () => {},
  referrerAppointment: {
    clinguId: null,
    date: null,
    caseId: null,
    type: null,
    clinicianSubType: null,
    appId: null
  },
  isAuthenticated: undefined,
  logRocketSessionURL: undefined
});

export default ContextApp;
