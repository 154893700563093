import ServiceApi from 'vl-common/src/services/service.api';

const baseServiceUrl = `/notes`;

export interface Note {
  user_guid: string;
  added_by: string;
  date_added: Date;
  created_date: Date;
  patient_guid: string;
  note_text: string;
  clin_visible: boolean;
  parent_id?: number;
  note_id?: number;
  case_id?: number;
}

const ApiNotes = {
  getPatientNotes: (patient_guid: string, case_id: string) => {
    const url = `${baseServiceUrl}/patient/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params: { patient_guid, case_id, rows: 9999 } });
  },

  getNoteHistoryByCaseId: (case_id: string | number) => {
    const url = `${baseServiceUrl}/appt/${ServiceApi.userGuid}?case_id=${case_id}`;
    return ServiceApi.get(url);
  },

  getNoteHistoryByAppointmentId: (appointment_id: string) => {
    const url = `${baseServiceUrl}/appt/${ServiceApi.userGuid}?appt_id=${appointment_id}`;
    return ServiceApi.get(url);
  },

  postPatientNotes: (patient_guid: string, note: Note) => {
    const url = `${baseServiceUrl}/patient`;
    return ServiceApi.post(url, { ...note, user_guid: ServiceApi.userGuid });
  },

  getNotes: (params = {}) => {
    const url = `${baseServiceUrl}/patient/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },

  putNotes: (params) => {
    const url = `${baseServiceUrl}/patient`;
    const body = { user_guid: ServiceApi.userGuid, ...params };
    return ServiceApi.post(url, body);
  },

  createNotes: (params) => {
    const url = `${baseServiceUrl}/patient/`;
    const body = { user_guid: ServiceApi.userGuid, ...params };
    console.debug('CREATE NOTE PRE-BODY', body);
    return ServiceApi.post(url, body);
  }
};

export default ApiNotes;
