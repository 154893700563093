import ServiceApi from '../services/service.api';

const baseServiceUrl = `/cases`;

const ApiCases = {
  getCases: (params) => {
    const url = `${baseServiceUrl}/${ServiceApi.userGuid}`;
    return new Promise((resolve, reject) => {
      ServiceApi.get(url, { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },
  postReopenCase: (params) => {
    const url = `${baseServiceUrl}/reopen`;
    const body = {
      user_guid: ServiceApi.userGuid,
      ...params
    };
    console.debug('post Re-open case PRE-BODY', body);
    return ServiceApi.post(url, body);
  },
  createCase: (
    patient_guid = '',
    cat_code = '',
    area_code = '',
    client_case_id = '',
    authCode = false,
    notes = '',
    urg_code = ''
  ) => {
    const url = `${baseServiceUrl}/new`;
    const body = {
      user_guid: ServiceApi.userGuid,
      pat_guid: patient_guid,
      speccat: cat_code,
      specarea: area_code,
      client_case_id: client_case_id,
      auth_code: authCode,
      current_notes: notes,
      urg_code
    };
    return ServiceApi.put(url, body);
  },
  putCreateCase: (params) => {
    const url = `${baseServiceUrl}/new`;
    const body = {
      user_guid: ServiceApi.userGuid,
      ...params
    };
    return ServiceApi.put(url, body);
  },
  removeAuth: (case_id, dry_run) => {
    const url = `/booking/appointment/auth/remove`;
    const body = {
      user_guid: ServiceApi.userGuid,
      case_id,
      dry_run: dry_run || 0
    };
    return ServiceApi.put(url, body);
  },
  addAuth: (case_id, appt_type_code, user_subtype_code) => {
    const url = `/booking/appointment/auth`;
    const body = {
      user_guid: ServiceApi.userGuid,
      case_id,
      appt_type_code,
      user_subtype_code
    };
    return ServiceApi.put(url, body);
  },
  updateCaseRef: (case_id, client_case_id) => {
    const url = `${baseServiceUrl}/ref`;
    const params = {
      user_guid: ServiceApi.userGuid,
      case_id,
      client_case_id
    };
    return ServiceApi.post(url, params);
  },
  searchForCases: (params) => {
    const url = `${baseServiceUrl}/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  referralDischarge: (body) => {
    const url = `${baseServiceUrl}/discharge`;
    const params = { user_guid: ServiceApi.userGuid, ...body };
    return ServiceApi.post(url, params);
  },
  postDischargeCase: (body) => {
    const url = `${baseServiceUrl}/discharge`;
    let temp = {
      user_guid: ServiceApi.userGuid,
      ...body
    };
    return ServiceApi.post(url, temp);
  },
  getCaseEligibility: (caseId) => {
    const params = { case_id: caseId };
    const url = `${baseServiceUrl}/eligibility/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  changePriority: (caseId, urgCode, urgChangeCode) => {
    const url = `${baseServiceUrl}/urgency`;
    const body = {
      user_guid: ServiceApi.userGuid,
      case_id: caseId,
      urg_code: urgCode,
      urgency_change_code: urgChangeCode
    };
    return ServiceApi.post(url, body);
  }
};

export default ApiCases;
