import { Amplify } from 'aws-amplify';
import { getSettingsUrl } from 'vl-common/src/lib/getSettingsURL';

const loadConfigIntoAmplify = (config) => {
  const amplifyConfiguration = {
    Auth: {
      mandatorySignId: true,
      region: config.APP_REGION,
      userPoolId: config.APP_USER_POOL_ID,
      userPoolWebClientId: config.APP_CLIENT_ID,
      authenticationFlowType: config.APP_FLOW,
      storage: sessionStorage
    },
    API: {
      endpoints: [
        {
          name: process.env.REACT_APP_AWS_ENDPOINT_NAME,
          endpoint: config.API_URL
        }
      ]
    }
  };
  Amplify.configure(amplifyConfiguration);
};

const setAmplifyForLocalHost = (config) => {
  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );
  const oauth = config.IDENTITY_PROVIDERS;
  const baseAdminUrl = getSettingsUrl().split('triage-settings/')[1];
  const konfig = {
    Auth: {
      oauth: {
        domain: oauth.domain,
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
        redirectSignIn: `${isLocalhost ? window.location.origin : oauth.redirectSignIn}`,
        redirectSignOut: `${isLocalhost ? window.location.origin : oauth.redirectSignOut}`,
        responseType: 'code'
      },
      clientMetadata: { clientOrigin: `${isLocalhost ? baseAdminUrl : window.location.origin}` }
    }
  };

  Amplify.configure(konfig);
};

export { loadConfigIntoAmplify, setAmplifyForLocalHost };
