import React from 'react';

type Config = {
  ADOBE_SDK_KEY: string;
  API_URL: string;
  APP_CLIENT_ID: string;
  APP_FLOW: string;
  APP_REGION: string;
  APP_USER_POOL_ID: string;
  AWS_ENDPOINT_NAME: string;
  DATABASE_VERSION: string;
  GOOGLE_ANALYTICS_TAG: string;
  LOG_ROCKET_APP_ID: string;
  LUCY_ENV: string;
  MFA_LENGTH: string;
  NETWORK_LATENCY_WARNING_THRESHOLD: string;
  NETWORK_QUALITY_WARNING_THRESHOLD: string;
  NEXT_PUBLIC_RECAPTCHA_SITE_KEY: string;
  NODE_ENV: string;
  SELF_REFER_CLIENT_ID: number;
  SERENOVA_TENANT: string;
  SERVER_LOCALE: string;
  STRIPE_PUBLISHABLE_KEY: string;
  TEST_RANDOM_FAILURES: string;
  TEST_CLINICIAN_USERNAME: string;
  TEST_CLINICIAN_PASSWORD: string;
  THEME: string;
  TOAST_MESSAGES: string;
  TWILIO_BACKEND_URL: string;
  UBRN_ENABLED: string;
};

const blankConfig = {
  ADOBE_SDK_KEY: '',
  API_URL: '',
  APP_CLIENT_ID: '',
  APP_FLOW: '',
  APP_REGION: '',
  APP_USER_POOL_ID: '',
  AWS_ENDPOINT_NAME: '',
  DATABASE_VERSION: '',
  GOOGLE_ANALYTICS_TAG: '',
  LOG_ROCKET_APP_ID: '',
  LUCY_ENV: '',
  MFA_LENGTH: '',
  NETWORK_LATENCY_WARNING_THRESHOLD: '',
  NETWORK_QUALITY_WARNING_THRESHOLD: '',
  NEXT_PUBLIC_RECAPTCHA_SITE_KEY: '',
  NODE_ENV: '',
  SELF_REFER_CLIENT_ID: 0,
  SERENOVA_TENANT: '',
  SERVER_LOCALE: '',
  STRIPE_PUBLISHABLE_KEY: '',
  TEST_RANDOM_FAILURES: '',
  TEST_CLINICIAN_USERNAME: '',
  TEST_CLINICIAN_PASSWORD: '',
  THEME: '',
  TOAST_MESSAGES: '',
  TWILIO_BACKEND_URL: '',
  UBRN_ENABLED: ''
};

const ConfigContext = React.createContext(blankConfig);
export default ConfigContext;
export { ConfigContext };
export type { Config };
