import React from 'react';
import { ToastBar, Toaster, toast } from 'react-hot-toast';
import { alreadyToasted, setToastError, toastDismissed } from 'vl-common/src/lib/toastUtils';

export function DismissibleToast() {
  return (
    <div>
      <Toaster
        reverseOrder={false}
        position="top-center"
        toastOptions={{
          style: {
            borderRadius: '8px',
            background: '#333',
            color: '#fff',
            maxWidth: '90vw'
          }
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <div
                role="button"
                tabIndex={-1}
                style={{ display: 'flex', flexDirection: 'row' }}
                onClick={() => {
                  if (t.type !== 'loading') {
                    toast.dismiss(t.id);
                    toastDismissed(t);
                  }
                }}
              >
                {icon}
                {message}
              </div>
            )}
          </ToastBar>
        )}
      </Toaster>
    </div>
  );
}

export class AdminErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // @ts-ignore
    if (!alreadyToasted(error)) {
      // this gets the error registered until the toast id is known. This stops the same error message
      // from piling up
      // @ts-ignore
      setToastError({}, error);
      toast.error(
        (toast) => {
          setToastError(toast, error);
          return <pre style={{ color: 'white' }}>{error.response?.data?.message || error.message}</pre>;
        },
        { duration: Infinity }
      );
    }

    delete error.stack;
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default AdminErrorBoundary;

export { alreadyToasted };
