import ServiceApi from '../services/service.api';

const baseServiceUrl = `/outputs`;

const ApiOutputs = {
  postNotificationPref: (params) => {
    const url = `${baseServiceUrl}/smspref`;
    const body = {
      user_guid: ServiceApi.userGuid,
      ...params
    };
    return ServiceApi.post(url, body);
  },
  getAll: (params) => {
    const url = `${baseServiceUrl}/def/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  getByIdWithJson: (template_id, client_id) => {
    const url = `${baseServiceUrl}/def/${ServiceApi.userGuid}?template_id=${template_id}&client_id=${client_id}&draft=true`;
    return ServiceApi.get(url);
  },
  saveAsDraft: (id, title, subject, data) => {
    const url = `${baseServiceUrl}/def`;
    return ServiceApi.post(url, {
      template_id: id,
      title: title,
      output_subject: subject,
      publish: false,
      output_body: data,
      user_guid: ServiceApi.userGuid
    });
  },
  saveAsPublished: (id, title, subject, data, sms) => {
    const url = `${baseServiceUrl}/def`;
    return ServiceApi.post(url, {
      template_id: id,
      title: title,
      output_subject: subject,
      publish: true,
      output_body: data,
      user_guid: ServiceApi.userGuid,
      output_sms_body: sms
    });
  },
  getByIdWithJsonLatest: (id, clientId) => {
    const url = `${baseServiceUrl}/def/${ServiceApi.userGuid}?template_id=${id}&client_id=${clientId}&draft=false`;
    return ServiceApi.get(url);
  },
  postLetterPreview: (templateCode) => {
    const url = `/pdfgen`;
    return ServiceApi.post(url, {
      letter_code: templateCode,
      preview: true,
      sub_tokens: false
    });
  },
  postEmail: (id, title, subject, data, sms, langCode = 'en') => {
    const url = `${baseServiceUrl}/def`;
    return ServiceApi.post(url, {
      template_id: id,
      lang_code: langCode,
      title: title,
      output_subject: subject,
      publish: false,
      output_body: data,
      user_guid: ServiceApi.userGuid,
      email_preview: true,
      output_sms_body: sms
    });
  }
};

export default ApiOutputs;
