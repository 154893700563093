import ServiceApi from '../services/service.api';

const baseServiceUrl = `/referrers`;

const ApiReferrers = {
  getPatientList: (params = {}) => {
    const url = `${baseServiceUrl}/patients/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  getPatient: (patientGuid, params = { pat_guid: patientGuid }) => {
    const url = `${baseServiceUrl}/patients/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  searchForCases: (params) => {
    const url = `/cases/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params });
  },
  postEligibilityPolicy: (caseId, policyCode, applied) => {
    const body = { case_id: caseId, policy_code: policyCode, applied: applied };
    const url = `/admin/case/policy/${ServiceApi.userGuid}`;
    return ServiceApi.post(url, body);
  }
};

export default ApiReferrers;
