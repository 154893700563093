import React, { useState } from 'react';
import { Form as AntdForm } from 'ALIASED-antd';
import { FormProps } from 'ALIASED-antd';
import { FormProvider } from 'ALIASED-antd/lib/form/context';
import ErrorList from 'ALIASED-antd/lib/form/ErrorList';
import { useForm, useWatch } from 'ALIASED-antd/lib/form/Form';
import Item from 'ALIASED-antd/lib/form/FormItem';
import List from 'ALIASED-antd/lib/form/FormList';
import { Spin } from 'ALIASED-antd';
import useFormInstance from 'ALIASED-antd/lib/form/hooks/useFormInstance';

interface CustomFormProps extends FormProps {
  formRef?: React.MutableRefObject<any>; // Adjust the type as needed
  children: React.ReactNode;
}

const CustomForm: React.FC<CustomFormProps> & {
  useForm: typeof useForm;
  useFormInstance: typeof useFormInstance;
  useWatch: typeof useWatch;
  Item: typeof Item;
  List: typeof List;
  ErrorList: typeof ErrorList;
  Provider: typeof FormProvider;
  create: () => void;
} = ({ onFinish, formRef, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  if (formRef) {
    formRef.current = form;
  }

  const handleFinish = async (values: any) => {
    if (!onFinish) return;

    setLoading(true);
    try {
      const asyncOnFinish = onFinish as (values: any) => Promise<void>;

      await asyncOnFinish(values);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading} delay={300}>
      <AntdForm form={form} onFinish={handleFinish} {...rest}>
        {rest.children}
      </AntdForm>
    </Spin>
  );
};

CustomForm.useForm = AntdForm.useForm;
CustomForm.useFormInstance = useFormInstance;
CustomForm.useWatch = useWatch;
CustomForm.Item = Item;
CustomForm.List = List;
CustomForm.ErrorList = ErrorList;
CustomForm.Provider = FormProvider;
CustomForm.create = AntdForm.create;

export default CustomForm;
