// @ts-nocheck
// import { LogRocket } from './LogRocketInit';
import { Auth, Hub } from 'aws-amplify';
import React, { useState, useEffect } from 'react';
import 'ALIASED-antd/dist/antd.variable.css';
import 'vl-common/src/components/AddPatient/add-patient.css';
import 'vl-common/src/components/TimeLine/provider-card.css';
import './assets/sass/app/components/PostcodeAutocomplete.scss';
import './assets/sass/app/components/PostcodeAutocomplete.scss';
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { Api as LocalApi } from 'vl-common/src/api/index';
import ConfigLink from './core/configs/config.link';
import ContextApp from 'vl-common/src/context/context.app';
import ProviderApp from 'vl-common/src/context/provider.app';
import CpBaseLoading from './ui/components/base/cp.base.loading';
import ForgetPassword from './ui/views/Account/ForgetPassword';
import LogRocket from 'logrocket';
import './app.css';
import 'vl-common/src/components/BookAppointment/calendar.css';
// pages
import Login from './ui/views/Account/Login';
import ResetYourPassword from './ui/views/Account/ResetYourPassword';
import WrongFlow from './ui/views/Account/WrongFlow';
import AdminErrorBoundary, { DismissibleToast } from './ui/components/AdminErrorBoundary';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { setLocalisation } from '@lib/localisation';
import { ConfigContext } from './core/contexts/backendConfig';
import { getDynamicConfig } from './core/hooks/useDynamicConfig';
import { loadConfigIntoAmplify } from './core/configs/config.app';
import { setRuntimeConfig, useRuntimeConfig } from 'vl-common/src/hooks/Runtime';
import Footer from 'vl-common/src/components/Footer/Footer';
import useRedirectToBuildPath from 'vl-common/src/hooks/useRedirectToBuildPath';
import { HarReadyProvider, useHarReady } from 'vl-common/src/hooks/useHARReady';
import { resetHARServiceWorkerAsRequired } from 'vl-common/src/hooks/useHAR';
import './AppFonts';
import './init';
import saveAuthAndRedirect, { getIsLocalhostOrNgrok } from 'vl-common/src/lib/saveAuthAndRedirect';

// containers
const AppLayoutAdmin = React.lazy(() => import('./ui/containers/AppLayout/AppLayout-Admin'));
const AppLayoutReferrer = React.lazy(() => import('./ui/containers/AppLayout/AppLayout-Referrer'));
const AppLayoutReferrerAdmin = React.lazy(() => import('./ui/containers/AppLayout/AppLayout-ReferrerAdmin'));
// public - without authentication
const ConfirmEmail = React.lazy(() => import('./ui/views/Account/ConfirmEmail'));
const ImagingStatusForm = React.lazy(() => import('./ui/views/External/ImagingStatusForm'));

resetHARServiceWorkerAsRequired();

function onEveryTick(fn, tick) {
  let getTick = () => tick - (Date.now() % tick);
  let f = () => {
    fn();
    setTimeout(f, getTick());
  };
  setTimeout(f, getTick());
}

// keep the access token updated
async function checkSession() {
  let currentSession;

  // don't do this whilst HAR testing
  if (Auth.authenticatedUser) return;

  // try to renew the cognito access token (if required)
  try {
    currentSession = await Auth.currentSession();
  } catch (error) {
    console.debug('checkSession Auth.currentSession() Error: ', error);
  }

  try {
    if (currentSession) {
      // expired session
      if (!window.checkExpireSession(true)) {
        console.debug('checkSession logout');
        await LocalApi.user.logoutUser();
        await Auth.signOut({ global: true });
        sessionStorage.clear();
        window.location.replace('#' + ConfigLink.AccountLogin);
        window.location.reload();
      }
      // refresh token
      else {
        console.debug('checkSession refreshToken');
        const token = `Bearer ${currentSession.getIdToken().getJwtToken()}`;
        await LocalApi.user.refreshToken(token);
        const tokenexp = new Date(currentSession.accessToken.payload.exp * 1000);
        console.debug(`Ticker: ${currentSession.idToken.payload.email} expires ${tokenexp}`);
      }
    }
  } catch (error) {
    console.debug('checkSession Error: ', error);
  }
}

export function connectLogRocket(user, context, config) {
  const { LOG_ROCKET_APP_ID } = config;

  if (LOG_ROCKET_APP_ID && user && (user.email || user.attributes?.email)) {
    LogRocket.init(LOG_ROCKET_APP_ID, { shouldDebugLog: true, dom: { inputSanitizer: false } });

    const { user_type_code, first_name, last_name, tel_mobile_no, email, user_guid } = user;
    const traits = {
      email,
      name: `${first_name} ${last_name}`,
      id: user_guid,
      user_type_code,
      tel_mobile_no
    };

    LogRocket.identify(email, traits);

    try {
      LogRocket.getSessionURL((sessionURL) => {
        context.setAuth((auth) => ({ ...auth, logRocketSessionURL: sessionURL }));
      });
    } catch (e) {
      console.error(e);
    }
  }
}

function tryAutoSSO() {
  if (/[?].*sso=/.test(window.location.href)) {
    const [hashQueryString] = window.location.href.split('?');
    const params = new URLSearchParams(hashQueryString);

    if (params.has('sso')) {
      const provider = params.get('sso');
      const page = window.location.hash.substring(1).split('?')[0];

      // I don't really care if SSO worked either way, I just don't want to try again
      params.delete('sso');

      console.dir({ provider });
      console.dir({ redirectTo: `${page}?${params.toString()}` });

      getDynamicConfig()
        .then(() => sessionStorage.setItem('sso-redirect', `${page}?${params.toString()}`))
        .then(() => Auth.federatedSignIn({ provider }));

      return <CpBaseLoading />;
    }
  }

  return null;
}

const MaybeLoadHARFile = ({ context }) => {
  const { isReady } = useHarReady();

  return (
    <>
      <DismissibleToast />
      <AdminErrorBoundary>
        {isReady && (
          <Switch>
            <Route
              path={ConfigLink.ImagingRequestStatusForm}
              name="ImagingStatusForm"
              render={() => <ImagingStatusForm />}
            />
            <Route path={ConfigLink.ConfirmEmail} name="ConfirmEmail" render={(props) => <ConfirmEmail {...props} />} />
            <Route
              path={ConfigLink.AccountLogin + '/:credentials?'}
              name="Login"
              render={(props) => <Login {...props} />}
            />

            <Route
              exact
              path={ConfigLink.ForgetPassword}
              name="ForgetPassword"
              render={(props) => <ForgetPassword {...props} />}
            />

            <Route
              exact
              path={ConfigLink.ResetYourPassword}
              name="ResetYourPassword"
              render={(props) => <ResetYourPassword {...props} />}
            />

            <Route exact path={ConfigLink.wrongFlow} name="WrongFlow" render={(props) => <WrongFlow {...props} />} />

            {context.auth.isAuthenticated && context.auth.user.user_type_code === 'CLIENTADMIN' && (
              <Route path="/admin" name="Admin" render={(props) => <AppLayoutAdmin {...props} />} />
            )}

            {context.auth.isAuthenticated && context.auth.user.user_type_code === 'REFERRER' && (
              <Route path="/referrer" name="Referrer" render={(props) => <AppLayoutReferrer {...props} />} />
            )}

            {context.auth.isAuthenticated && context.auth.user.user_type_code === 'ADMIN' && (
              <Route path="/referrer-admin" name="Referrer" render={(props) => <AppLayoutReferrerAdmin {...props} />} />
            )}

            {context.auth.isAuthenticated && <Redirect exact={true} from="/" to={ConfigLink.AccountLogin} />}

            {!context.auth.reload && <Redirect from="*" to={ConfigLink.AccountLogin} />}
          </Switch>
        )}
        <Footer logRocketSessionURL={context?.auth?.logRocketSessionURL} getDynamicConfig={getDynamicConfig} />
      </AdminErrorBoundary>
    </>
  );
};

// This callback handles story branch redirects after SSO
Hub.listen('auth', ({ payload: { event, data } }) => {
  if (event === 'customOAuthState') {
    const { storyPath } = JSON.parse(atob(data));

    if (storyPath && window.location.pathname !== storyPath) {
      window.location.pathname = storyPath;
    }
  }
});

function MaybeRedirectToBuildPath({ children }) {
  useRedirectToBuildPath();
  return children;
}

const getIsUserOnProperHost = (loginUrl) => {
  if (!loginUrl) {
    return false;
  }
  return new URL(loginUrl).hostname === window.location.hostname;
};

function App() {
  const [queryClient] = useState(() => new QueryClient({ defaultOptions: { queries: { staleTime: 100 * 1000 } } }));
  const [ssoSpinner] = useState(() => {
    tryAutoSSO();
  });

  const [contextValue, setContextValue] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const config = useRuntimeConfig();

  useEffect(() => {
    window.addEventListener('popstate', () => {
      console.log('Location changed:', window.location.href);
    });

    getDynamicConfig().then(({ config, response }) => {
      if (response.ok) {
        loadConfigIntoAmplify(config);
        setContextValue(config);
        setRuntimeConfig(config);
        setIsReady(true);
      } else {
        window.location.replace('/errors/404/index.html');
      }
    });
  }, []);

  if (ssoSpinner) return ssoSpinner;

  if (!isReady) {
    return <p>Loading system configuration ...</p>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <HarReadyProvider>
        <ProviderApp>
          {isReady && (
            <ConfigContext.Provider value={contextValue}>
              <ContextApp.Consumer>
                {(context) => {
                  (async () => {
                    try {
                      if (!context.auth.authentication && contextValue !== null) {
                        await Auth.currentSession();
                        const user = await Auth.currentAuthenticatedUser();
                        let result = await LocalApi.user.getCurrentUser();

                        user.full_name = `${result.data.first_name} ${result.data.last_name}`;
                        console.debug(result.data);
                        context.setAuth({
                          user: {
                            ...user,
                            ...result.data
                          },
                          isAuthenticated: true,
                          authentication: true,
                          reload: false
                        });

                        const { timezone, lang_code, login_url } = result.data;
                        setLocalisation(lang_code, timezone);

                        onEveryTick(() => checkSession(), 15e4);
                        connectLogRocket(user, context, config);

                        // if the user has logged into the wrong host redirect them to proper host
                        const isUserOnProperHost = getIsUserOnProperHost(login_url);
                        const isLocalhostOrNgrok = getIsLocalhostOrNgrok();

                        if (!isUserOnProperHost && !isLocalhostOrNgrok && login_url) {
                          await saveAuthAndRedirect(login_url, true);
                        }
                      }
                    } catch (err) {
                      context.setAuth({
                        user: {},
                        isAuthenticated: false,
                        authentication: true,
                        reload: false
                      });
                      console.warn(err);
                    }
                  })();

                  return (
                    <Router>
                      {context.auth.authentication && (
                        <MaybeRedirectToBuildPath>
                          <React.Suspense fallback={<CpBaseLoading />}>
                            <QueryParamProvider ReactRouterRoute={Route}>
                              <MaybeLoadHARFile context={context} />
                            </QueryParamProvider>
                          </React.Suspense>
                        </MaybeRedirectToBuildPath>
                      )}
                    </Router>
                  );
                }}
              </ContextApp.Consumer>
            </ConfigContext.Provider>
          )}
        </ProviderApp>

        <div data-chromatic="ignore">
          <ReactQueryDevtools initialIsOpen={false} />
        </div>
      </HarReadyProvider>
    </QueryClientProvider>
  );
}

export default App;
