import ServiceApi from '../services/service.api';

const baseServiceUrl = `/provider-token`;

const ApiProviderToken = {
  getImagingReqPatientDetails: (patient_guid, token) => {
    const url = `${baseServiceUrl}/${patient_guid}?token=${token}`;
    return ServiceApi.get(url, null, false);
  },
  getImagingReqMeta: (patient_guid, token) => {
    const url = `${baseServiceUrl}/meta/${patient_guid}?token=${token}`;
    return ServiceApi.get(url, null, false);
  },
  setImagingReqStatus: (patient_guid, token, appt_date, status) => {
    const url = `${baseServiceUrl}/${patient_guid}`;
    const body = {
      token: token,
      appt_date: appt_date,
      status: status
    };
    return ServiceApi.post(url, body, false);
  }
};
export default ApiProviderToken;
