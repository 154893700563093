import ServiceApi from '../services/service.api';

const baseServiceUrl = `/tags`;

const ApiTags = {
  setTags: (user_guid, infoTagsList, filterTagsList = []) => {
    const params = {
      user_guid: ServiceApi.userGuid,
      up_user_guid: user_guid,
      info_tags: infoTagsList,
      filter_tags: filterTagsList
    };
    return ServiceApi.post(`${baseServiceUrl}/user`, params);
  }
};

export default ApiTags;
