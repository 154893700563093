import { Api } from '../api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useToaster } from 'react-hot-toast';
import { alreadyToasted } from '../lib/toastUtils';

// associates a promise with a "context", where a context is a three element object of
// {key, args, setError}. key is the dotted "name" of an API function, args are the
// arguments passed to the Api call and setError is a state updater (of {error, originator}).
// The originator is the original promise that initiated the API call.
const PromiseOriginator = new WeakMap<Promise<any>, { key: string; args: any[]; setError: (e: Error) => void }>();

// marks an error as "consumed"
const ConsumedErrors = new WeakSet<Error>();

function formatErrorArgs(args: string | any[]) {
  if (args.length === 0) {
    return '';
  }

  if (args.length === 1) {
    return `\n${JSON.stringify(args[0], null, 4)}`;
  }

  return `\n${JSON.stringify(args, null, 4)}`;
}

// Promise extension used to process API calls. Essentially extends "catch" so that a
// "consume" callback function is supplied as a second parameter to the catch handler.
// The catch behaviour is extended so that after the original catch handler returns
// the revised handler checks to so if the error has been consumed. If not it adds it
// to the error list associated with the invoking useApi hook call.
class ApiPromise<T = unknown> extends Promise<T> {
  static myResolve(c) {
    const promise = ApiPromise.resolve();

    PromiseOriginator.set(promise, c);

    return promise;
  }

  then<TResult1 = T, TResult2 = never>(
    onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | null | undefined,
    onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null | undefined
  ): Promise<TResult1 | TResult2> {
    const p = super.then(onfulfilled, onrejected);

    // make sure that the new promise knows what it's doing!
    PromiseOriginator.set(p, PromiseOriginator.get(this));

    return p;
  }

  catch(onrejected: (e: any) => any) {
    return super.catch((e) => {
      const originator = PromiseOriginator.get(this);

      const p = onrejected?.(e);
      const consumed = ConsumedErrors.has(e);

      if (!consumed && originator) {
        const { setError, key, args } = originator;

        setError(Error(`An Error Occurred whilst executing: ${key}, ${e}` + formatErrorArgs(args)));
      }

      // make sure that the new promise knows what it's doing!
      if (p) {
        PromiseOriginator.set(p, PromiseOriginator.get(this));
      }

      return p;
    });
  }
}

// Wrap all the Api functions so that catch() calls on the Api promise have the option to "consume" them or not.
// By default they are unconsumed and therefore get added to an error list which is ultimately toasted via
// React ErrorBoundaries.
export default function useApi() {
  const [error, setError] = useState<Error>();
  const { toasts } = useToaster();

  function wrap<T>(key: string, asyncFn: (...args: any[]) => Promise<T>) {
    return (...args) => ApiPromise.myResolve({ key, args, setError }).then(() => asyncFn(...args));
  }

  const wrapFunctions = useCallback(<T>(o: T, keys: string[] = []): T => {
    return Object.fromEntries(
      Object.entries(o).map(([key, value]) => [
        key,
        typeof value === 'function' ? wrap([...keys, key].join('.'), value) : wrapFunctions(value, [...keys, key])
      ])
    ) as T;
  }, []);

  useEffect(() => {
    if (error && !alreadyToasted(error)) {
      // there is no need to clear the "error" state variable because this component is about to unmount anyway
      throw error;
    }
  }, [error, toasts]);

  return {
    Api: useMemo(() => wrapFunctions(Api), [wrapFunctions]),
    toasted: useCallback((e) => {
      setError(e);
    }, []),
    consumed: useCallback((e) => ConsumedErrors.add(e), [])
  };
}
