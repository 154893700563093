import { Toast } from 'react-hot-toast';

const errorsByToastId = new Map<string, Error>();
const toastToErrorMap = new WeakMap<Toast, Error>();

export function setToastError(toast: Toast, err: Error) {
  errorsByToastId.set(toast.id, err);
  toastToErrorMap.set(toast, err);
}

export function toastDismissed(toast: Toast) {
  errorsByToastId.delete(toast.id);
}

export function toastToError(toast: Toast) {
  return toastToErrorMap.get(toast);
}

function matchingError(lhs: Error, rhs: Error) {
  return lhs.message === rhs.message;
}

export function alreadyToasted(error: Error, toasts: Toast[] = []): boolean {
  const toastErrors = Array.from(errorsByToastId.values());

  return toastErrors.some((e) => matchingError(e, error));
}
