import React, { createContext, useContext, useState } from 'react';

const ReadyContext = createContext({ isReady: false, setReady: () => {} });

export const useHarReady = () => useContext(ReadyContext);

export const HarReadyProvider = ({ children }: { children: React.ReactNode }) => {
  const [isReady, setIsReady] = useState(false);
  const setReady = () => setIsReady(true);

  return <ReadyContext.Provider value={{ isReady, setReady }}>{children}</ReadyContext.Provider>;
};
