import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';

export function useRouter() {
  const history = useHistory();
  return useMemo(
    () => ({
      push: (route: string) => {
        history.push(route);
        return Promise.resolve();
      },
      replace: (route: string) => {
        history.replace(route);
      }
    }),
    [history]
  );
}

export default useRouter;
