import ServiceApi from 'vl-common/src/services/service.api';

const baseServiceUrl = `/proxy`;

export interface ProxyMatch {
  user_guid: string;
  proxy_id: number;
  client_name: string;
  membership_no: string;
  dob: string;
  title: string;
  first_name: string;
  last_name: string;
  email: string;
  tel_mobile_no: string;
}

interface PostProxyMatchSelectionRequest {
  user_guid: string;
  proxy_id: number;
  proxy_user_guid: string | null;
  new_user: boolean;
  authorise: boolean;
}

const ApiProxies = {
  getProxyMatches: (
    proxyId: number
  ): Promise<{
    data: {
      proxy_requests: ProxyMatch[];
    };
  }> => {
    const url = `${baseServiceUrl}/matches/${ServiceApi.userGuid}`;
    return ServiceApi.get(url, { params: { proxy_id: proxyId } });
  },

  postProxyMatchSelection: ({
    proxyId,
    patientGuid,
    authorise,
    newUser = false
  }:
    | {
        proxyId: number;
        patientGuid: string;
        authorise: boolean;
        newUser: false;
      }
    | {
        proxyId: number;
        patientGuid: null;
        authorise: boolean;
        newUser: true;
      }) => {
    const request: PostProxyMatchSelectionRequest = {
      user_guid: ServiceApi.userGuid,
      proxy_id: proxyId,
      proxy_user_guid: patientGuid,
      new_user: newUser,
      authorise
    };

    const url = `${baseServiceUrl}/auth`;
    return ServiceApi.post(url, request);
  }
};

export default ApiProxies;
