import React from 'react';
import useSystemType from 'vl-core/hooks/useSystemType';

/**
 * Renders children only if the application name type matches one of the supplied prop types (either "app",
 * "referrer" or "clientAdmin"). Note that the type/flag/attributes are case insensitive.
 *
 */

export const SystemTypeRender = (props: { children: React.ReactNode; APP?: boolean; ADMIN?: boolean }) => {
  const { children, ...types } = props;
  const systemType = useSystemType();
  const convertedSystemType = systemType?.split('-')?.[1]?.toUpperCase();

  for (const type in types) {
    if (types[type] && type.toUpperCase() === convertedSystemType) {
      return <>{children}</>;
    }

    if (!['ADMIN', 'APP'].includes(type)) {
      throw Error(`Bad type specified for "DisplayForUserTypes": "${type}"`);
    }
  }

  return null;
};

export default SystemTypeRender;
