import ServiceApi from '../services/service.api';

const baseServiceUrl = `/gdpr`;

const ApiGDPR = {
  putGDPR: async (params = {}) => {
    const url = `${baseServiceUrl}/request`;
    const body = {
      user_guid: ServiceApi.userGuid,
      ...params
    };
    return await ServiceApi.put(url, body);
  }
};

export default ApiGDPR;
